import Vue from "vue";
import VueRouter from "vue-router";

// Libraries
import { auth } from "@/firebase/init";
import store from "@/store/index.js";

const originalPush = VueRouter.prototype.push;
const { isNavigationFailure, NavigationFailureType } = VueRouter;

VueRouter.prototype.push = function (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/404",
    component: () => import("@/components/NotFound.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/components/NotAllowed.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/components/NotFound.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/landing/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/landing/Login.vue"),
  },
  {
    path: "/buyer/:id",
    name: "salesBuyer-order",
    component: () => import("@/components/misc/dashboards/vendors/Sales.vue"),
  },
  /* Phase 0 - Admin */
  // User MGMT
  {
    path: "/users/overview",
    name: "users",
    component: () => import("@/views/phases/0_admin/users/Users_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/new",
    name: "user-new",
    component: () => import("@/views/phases/0_admin/users/User_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/:id",
    name: "user-view",
    component: () => import("@/views/phases/0_admin/users/User_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/:id",
    name: "profile-view",
    component: () => import("@/components/misc/UserProfile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/promotionCredit/:id",
    name: "promotionCredit-view",
    component: () => import("@/components/misc/PromotionCredit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/__/auth/handler",
    name: "oAuth-handler",
    component: () => import("@/components/misc/OAuthHandler.vue"),
  },
  // Vendor Logins
  {
    path: "/vendorLogins/overview",
    name: "vendorLogins-overview",
    component: () =>
      import("@/views/phases/0_admin/vendorLogins/VendorLogins_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorLogins/new",
    name: "vendorLogin-new",
    component: () =>
      import("@/views/phases/0_admin/vendorLogins/VendorLogin_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorLogin/:id",
    name: "vendorLogin-view",
    component: () =>
      import("@/views/phases/0_admin/vendorLogins/VendorLogin_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Data Management
  {
    path: "/dataManagement/overview",
    name: "dataManagement-overview",
    component: () =>
      import("@/views/phases/0_admin/dataManagement/DataImport_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/mytrace/import",
    name: "dataManagement-mytrace-import",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/mytrace/DataImport_MyTrace_Import.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/mytrace/accounts",
    name: "dataManagement-mytrace-account",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/mytrace/DataImport_MyTrace_AccountMerge.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/mytrace/account-transfer",
    name: "dataManagement-mytrace-account-transfer",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/mytrace/DataImport_MyTrace_AccountTransfer.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/mytrace/account-export",
    name: "dataManagement-mytrace-account-export",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/mytrace/DataImport_MyTrace_AccountExport.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/mytrace/retag",
    name: "dataManagement-mytrace-retag",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/mytrace/DataImport_MyTrace_Retag.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/mytrace/socials",
    name: "dataManagement-mytrace-socials",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/mytrace/DataImport_MyTrace_Socials.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/chaski/brands",
    name: "dataManagement-chaski-brands",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/chaski/DataImport_Chaski_Brands.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/chaski/products",
    name: "dataManagement-chaski-products",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/chaski/DataImport_Chaski_Products.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/chaski/accounts",
    name: "dataManagement-chaski-accounts",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/chaski/DataImport_Chaski_Accounts.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/chaski/orders",
    name: "dataManagement-chaski-orders",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/chaski/DataImport_Chaski_Orders.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/apextrading/accounts",
    name: "dataManagement-apextrading-accounts",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/apextrading/DataImport_ApexTrading_Accounts.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/apextrading/orders",
    name: "dataManagement-apextrading-orders",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/apextrading/DataImport_ApexTrading_Orders.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/dataManagement/salesforce/accounts",
  //   name: "dataManagement-salesforce-accounts",
  //   component: () =>
  //     import(
  //       "@/views/phases/0_admin/dataManagement/salesforce/DataImport_SF_Accounts.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/dataManagement/salesforce/contacts",
  //   name: "dataManagement-salesforce-contacts",
  //   component: () =>
  //     import(
  //       "@/views/phases/0_admin/dataManagement/salesforce/DataImport_SF_Contacts.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/dataManagement/salesforce/tasks",
  //   name: "dataManagement-salesforce-tasks",
  //   component: () =>
  //     import(
  //       "@/views/phases/0_admin/dataManagement/salesforce/DataImport_SF_Tasks.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/dataManagement/salesforce/storeEvents",
  //   name: "dataManagement-salesforce-storeEvents",
  //   component: () =>
  //     import(
  //       "@/views/phases/0_admin/dataManagement/salesforce/DataImport_SF_StoreEvents.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/dataManagement/salesforce/budtenderTrainings",
  //   name: "dataManagement-salesforce-budtenderTrainings",
  //   component: () =>
  //     import(
  //       "@/views/phases/0_admin/dataManagement/salesforce/DataImport_SF_BudtenderTrainings.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/dataManagement/salesforce/final",
  //   name: "dataManagement-salesforce-final",
  //   component: () =>
  //     import(
  //       "@/views/phases/0_admin/dataManagement/salesforce/DataImport_SF_Final.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/dataManagement/leaflink/sync",
    name: "dataManagement-leaflink-sync",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/leaflink/DataImport_LeafLink_Sync.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/leaflink/accounts",
    name: "dataManagement-leaflink-accounts",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/leaflink/DataImport_LeafLink_Accounts.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataManagement/leaflink/salesOrders",
    name: "dataManagement-leaflink-salesOrders",
    component: () =>
      import(
        "@/views/phases/0_admin/dataManagement/leaflink/DataImport_LeafLink_SalesOrders.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Integrations
  {
    path: "/integrations/overview",
    name: "integrations-overview",
    component: () =>
      import("@/views/phases/0_admin/integrations/Integrations_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Operating Zones
  {
    path: "/operatingZones/overview",
    name: "operatingZones-overview",
    component: () =>
      import(
        "@/views/phases/0_admin/operatingZones/OperatingZones_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/country/new",
    name: "country-new",
    component: () =>
      import("@/views/phases/0_admin/operatingZones/countries/Country_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/country/:id",
    name: "country-view",
    component: () =>
      import(
        "@/views/phases/0_admin/operatingZones/countries/Country_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/state/new",
    name: "state-new",
    component: () =>
      import("@/views/phases/0_admin/operatingZones/states/State_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/state/:id",
    name: "state-view",
    component: () =>
      import("@/views/phases/0_admin/operatingZones/states/State_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/territory/new",
    name: "territory-new",
    component: () =>
      import(
        "@/views/phases/0_admin/operatingZones/territories/Territory_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/territory/:id",
    name: "territory-view",
    component: () =>
      import(
        "@/views/phases/0_admin/operatingZones/territories/Territory_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Email Templates
  {
    path: "/emailTemplates/overview",
    name: "emailTemplates-overview",
    component: () =>
      import(
        "@/views/phases/0_admin/emailTemplates/EmailTemplates_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/emailTemplate/new",
    name: "emailTemplate-new",
    component: () =>
      import("@/views/phases/0_admin/emailTemplates/EmailTemplate_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/emailTemplate/:id",
    name: "emailTemplate-view",
    component: () =>
      import("@/views/phases/0_admin/emailTemplates/EmailTemplate_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Email
  {
    path: "/emails/overview",
    name: "emails",
    component: () => import("@/views/phases/0_admin/emails/Email_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Tasks
  {
    path: "/tasks/overview",
    name: "tasks",
    component: () => import("@/views/phases/0_admin/tasks/Tasks_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/task/new",
    name: "task-new",
    component: () => import("@/views/phases/0_admin/tasks/Task_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/task/:id",
    name: "task-view",
    component: () => import("@/views/phases/0_admin/tasks/Task_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Project Templates
  {
    path: "/projectTemplates/overview",
    name: "projectTemplates",
    component: () =>
      import(
        "@/views/phases/0_admin/projectTemplates/ProjectTemplates_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projectTemplates/new",
    name: "projectTemplates-new",
    component: () =>
      import(
        "@/views/phases/0_admin/projectTemplates/ProjectTemplates_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projectTemplates/:id",
    name: "projectTemplates-view",
    component: () =>
      import(
        "@/views/phases/0_admin/projectTemplates/ProjectTemplates_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Project Management
  {
    path: "/projects/overview",
    name: "projects-overview",
    component: () =>
      import("@/views/phases/0_admin/projects/Projects_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/new",
    name: "projects-new",
    component: () => import("@/views/phases/0_admin/projects/Project_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:id",
    name: "projects-view",
    component: () => import("@/views/phases/0_admin/projects/Project_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // QR Templates
  {
    path: "/qrTemplates/overview",
    name: "qrTemplates",
    component: () =>
      import("@/views/phases/0_admin/qrTemplates/qrTemplates_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/qrTemplate/new",
    name: "qrTemplates-new",
    component: () => import("@/views/phases/0_admin/qrTemplates/qrTemplates_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/qrTemplate/:id",
    name: "qrTemplates-view",
    component: () => import("@/views/phases/0_admin/qrTemplates/qrTemplates_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // QR Codes
  {
    path: "/qrCodes/overview",
    name: "qrCodes",
    component: () =>
      import("@/views/phases/0_admin/qrCodes/qrCodes_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/qrCodes/new",
    name: "qrCodes-new",
    component: () =>
      import("@/views/phases/0_admin/qrCodes/qrCodes_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/qrCodes/:id",
    name: "qrCodes-view",
    component: () =>
      import("@/views/phases/0_admin/qrCodes/qrCodes_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/qrcode/:id",
    name: "qrCodes-read",
    component: () =>
      import("@/views/phases/0_admin/qrCodes/qrCode_Read.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  // Contacts
  {
    path: "/contact/new",
    name: "contacts-new",
    component: () => import("@/components/misc/contacts/Contact_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact/:id",
    name: "contacts-view",
    component: () => import("@/components/misc/contacts/Contact_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Phase 1 - Sourcing */
  // Vendors
  {
    path: "/vendors/overview",
    name: "vendors-overview",
    component: () =>
      import("@/views/phases/1_sourcing/vendors/Vendors_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendor/new",
    name: "vendors-new",
    component: () => import("@/views/phases/1_sourcing/vendors/Vendor_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendor/:id",
    name: "vendors-view",
    component: () =>
      import("@/views/phases/1_sourcing/vendors/Vendor_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Brands
  {
    path: "/brands/overview",
    name: "brands-overview",
    component: () =>
      import("@/views/phases/1_sourcing/brands/Brands_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whiteLabelBrands/overview",
    name: "whiteLabelBrands-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/whiteLabelBrands/WhiteLabelBrands_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whiteLabelBrand/new",
    name: "whiteLabelBrand-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/whiteLabelBrands/WhiteLabelBrand_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whiteLabelBrand/:id",
    name: "whiteLabelBrand-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/whiteLabelBrands/WhiteLabelBrand_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/brand/new",
    name: "brand-new",
    component: () =>
      import("@/views/phases/1_sourcing/brands/create/brand/Brand_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/brand/:id",
    name: "brand-view",
    component: () =>
      import("@/views/phases/1_sourcing/brands/update/brand/Brand_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/brand/:id/licensee/new",
    name: "payableTo-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/brands/create/payableTo/PayableTo_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/licensee/:id",
    name: "payableTo-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/brands/update/payableTo/PayableTo_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/priceOverride/:id",
    name: "priceOverride-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/brands/update/priceOverride/PriceOverride_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Product
  {
    path: "/products/overview",
    name: "products-overview",
    component: () =>
      import("@/views/phases/1_sourcing/products/Products_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whiteLabelProducts/overview",
    name: "whiteLabelProducts-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/products/WhiteLabelProducts_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/category/new",
    name: "category-new",
    component: () =>
      import("@/views/phases/1_sourcing/products/create/Category_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/category/:id",
    name: "category-view",
    component: () =>
      import("@/views/phases/1_sourcing/products/update/Category_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/new",
    name: "products-new",
    component: () =>
      import("@/views/phases/1_sourcing/products/create/Product_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/:id",
    name: "products-view",
    component: () =>
      import("@/views/phases/1_sourcing/products/update/Product_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Locations
  {
    path: "/locations/overview",
    name: "locations-overview",
    component: () =>
      import("@/views/phases/1_sourcing/locations/Locations_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/new",
    name: "locations-new",
    component: () =>
      import("@/views/phases/1_sourcing/locations/Location_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:id",
    name: "locations-view",
    component: () => import("@/views/phases/1_sourcing/locations/Location.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Vendor Orders
  {
    path: "/vendorOrders/overview",
    name: "vendorOrders-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/vendorOrders/VendorOrders_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorOrder/new/brand",
    name: "vendorOrder-brand-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/vendorOrders/create/VendorOrder_Brand.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorOrder/new/products",
    name: "vendorOrder-products-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/vendorOrders/create/VendorOrder_Products.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorOrder/new/review",
    name: "vendorOrder-review-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/vendorOrders/create/VendorOrder_Review.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorOrder/master/:id",
    name: "vendorOrder-master-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/vendorOrders/update/VendorOrder_Master.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorOrder/category/:id",
    name: "vendorOrder-productCategory-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/vendorOrders/update/VendorOrder_ProductCategory.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vendorOrder/invoice/:id",
    name: "vendorOrder-invoice-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/vendorOrders/update/VendorOrder_Invoice.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Custom Products
  {
    path: "/customProducts/overview",
    name: "customProducts-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/customProducts/CustomProducts_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customProductType/new",
    name: "customProductType-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/customProducts/productType/CustomProductType_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customProductCategory/new",
    name: "customProductCategory-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/customProducts/category/CustomProductCategory_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customProductCategory/:id",
    name: "customProductCategory-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/customProducts/category/CustomProductCategory_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customProducts/new",
    name: "customProducts-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/customProducts/products/CustomProduct_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customProduct/:id",
    name: "customProduct-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/customProducts/products/CustomProduct_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Custom Orders - Requests
  {
    path: "/customOrders/requests/new",
    name: "customOrder-request-new",
    component: () => {
      import(
        "@/views/phases/1_sourcing/customOrders/requests/CustomOrderRequest_New.vue"
      );
    },
    meta: {
      requiresAuth: true,
    },
  },
  // Custom Orders
  {
    path: "/customOrders/overview",
    name: "customOrders-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/customOrders/CustomOrders_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customOrder/new",
    name: "customOrder-new",
    component: () =>
      import("@/views/phases/1_sourcing/customOrders/CustomOrder_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customOrder/:id",
    name: "customOrder-view",
    component: () =>
      import("@/views/phases/1_sourcing/customOrders/CustomOrder_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Phase 2 - Manufacturing */
  // White Label Clients
  {
    path: "/whiteLabelClients/overview",
    name: "whiteLabelClients-overview",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/whiteLabelClients/WhiteLabelClients_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whiteLabelClient/new",
    name: "whiteLabelClients-new",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/whiteLabelClients/WhiteLabelClient_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whiteLabelClient/:id",
    name: "whiteLabelClients-view",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/whiteLabelClients/WhiteLabelClient_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Testing Labs
  {
    path: "/testingLabs/overview",
    name: "testingLabs-overview",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/testingLabs/TestingLabs_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/testingLab/new",
    name: "testingLabs-new",
    component: () =>
      import("@/views/phases/2_manufacturing/testingLabs/TestingLab_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/testingLab/:id",
    name: "testingLabs-view",
    component: () =>
      import("@/views/phases/2_manufacturing/testingLabs/TestingLab_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Terpenes
  {
    path: "/terpenes/overview",
    name: "terpenes-overview",
    component: () =>
      import("@/views/phases/2_manufacturing/terpenes/Terpenes_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/terpene/new",
    name: "terpenes-new",
    component: () =>
      import("@/views/phases/2_manufacturing/terpenes/Terpene_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/terpene/:id",
    name: "terpenes-view",
    component: () =>
      import("@/views/phases/2_manufacturing/terpenes/Terpene_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Raw Material Vendors
  {
    path: "/rawMaterialVendors/overview",
    name: "rawMaterialVendors-overview",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterialVendors/RawMaterialVendors_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rawMaterialVendor/new",
    name: "rawMaterialVendors-new",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterialVendors/RawMaterialVendor_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rawMaterialVendor/:id",
    name: "rawMaterialVendors-view",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterialVendors/RawMaterialVendor_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Raw Materials
  {
    path: "/rawMaterials/overview",
    name: "rawMaterials-overview",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterials/RawMaterials_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/rawMaterialCategory/new",
    name: "rawMaterialCategory-new",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterials/category/RawMaterialCategory_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rawMaterialCategory/:id",
    name: "rawMaterialCategory-view",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterials/category/RawMaterialCategory_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rawMaterial/new",
    name: "rawMaterials-new",
    component: () =>
      import("@/views/phases/2_manufacturing/rawMaterials/RawMaterial_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rawMaterial/:id",
    name: "rawMaterials-view",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterials/RawMaterial_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Mix Calculator
  {
    path: "/mixCalculator/overview",
    name: "mixCalculator-overview",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/mixCalculator/MixCalculator_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/productRecipes/edit",
    name: "productRecipes-edit",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/mixCalculator/ProductRecipes_Edit.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Raw Material Orders
  {
    path: "/rawMaterialOrders/overview",
    name: "rawMaterialOrders-overview",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterialOrders/RawMaterialOrders_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rawMaterialOrder/new",
    name: "rawMaterialOrder-new",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterialOrders/RawMaterialOrder_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rawMaterialOrders/:id",
    name: "rawMaterialOrders-view",
    component: () =>
      import(
        "@/views/phases/2_manufacturing/rawMaterialOrders/RawMaterialOrder_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // COAs
  {
    path: "/coa/overview",
    name: "coa-overview",
    component: () =>
      import("@/views/phases/2_manufacturing/coa/COA_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/coa/new",
    name: "coa-new",
    component: () => import("@/views/phases/2_manufacturing/coa/COA_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/coa/:id",
    name: "coa-view",
    component: () => import("@/views/phases/2_manufacturing/coa/COA_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Location Raw Materials
  {
    path: "/location/:locationID/rawMaterials/overview",
    name: "location-rawMaterials-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/rawMaterials/RawMaterials_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Location Terpenes
  {
    path: "/location/:locationID/terpenes/overview",
    name: "location-terpenes-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/terpenes/Terpenes_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Location Cultivation
  {
    path: "/location/:locationID/cultivation/overview",
    name: "location-cultivation-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/cultivation/Cultivation_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/room/add",
    name: "cultivation-room-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/cultivation/rooms/Room_Add.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/room/:id",
    name: "cultivation-room-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/cultivation/rooms/Room_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Harvest
  {
    path: "/location/:locationID/harvest/add",
    name: "harvest-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/cultivation/harvest/Harvest_Add.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/harvest/:id",
    name: "harvests-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/cultivation/harvest/Harvest_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Reconcile Inventory
  {
    path: "/location/:locationID/reconcile/overview",
    name: "inventory-reconcile-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/reconcile/Reconcile_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Transfer Inventory
  {
    path: "/location/:locationID/transfer/overview",
    name: "transfer-inventory-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/TransferInventory_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/transfer/location",
    name: "new-transfer-location-select",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/create/TransferInventory_Location.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/transfer/assembled",
    name: "transfer-assembled-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/create/TransferAssembled_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transfer/:locationID/assembled/:id",
    name: "transfer-assembled-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/update/TransferAssembled_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/transfer/components",
    name: "transfer-components-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/create/TransferComponents_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transfer/:locationID/components/:id",
    name: "transfer-components-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/update/TransferComponents_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/transfer/rawMaterials",
    name: "transfer-rawMaterials-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/create/TransferRawMaterials_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/transfer/:locationID/rawMaterials/:id",
    name: "transfer-rawMaterials-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/transfer/update/TransferRawMaterials_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Work Orders
  {
    path: "/location/:locationID/workOrders/overview",
    name: "manufacturing-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/manufacturing/ManufacturingOrders_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/workOrder/new/brand",
    name: "workOrder-brand-new",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/manufacturing/create/WorkOrder_Brand.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/workOrder/new/products",
    name: "new-workOrder-products",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/manufacturing/create/WorkOrder_Products.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/workOrder/new/review",
    name: "new-workOrder-review",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/manufacturing/create/WorkOrder_Review.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mainWorkOrder/:id",
    name: "mainWorkOrder-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/manufacturing/update/MainWorkOrder_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/productOrder/:id",
    name: "productWorkOrder-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/manufacturing/update/ProductWorkOrder_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // White Label Orders
  {
    path: "/location/:locationID/whiteLabelOrders/overview",
    name: "location-white-label-orders-overview",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/whiteLabelOrders/WhiteLabelOrders_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/whiteLabelInvoice/new/select",
    name: "whiteLabelOrder-new-select",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/whiteLabelOrders/create/WhiteLabelOrder_Select.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/:locationID/whiteLabelInvoice/new/review",
    name: "whiteLabelOrder-new-review",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/whiteLabelOrders/create/WhiteLabelOrder_Review.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/location/whiteLabelInvoice/:id",
    name: "whiteLabelInvoice-view",
    component: () =>
      import(
        "@/views/phases/1_sourcing/locations/whiteLabelOrders/update/WhiteLabelOrder_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  /* Phase 3 - Sales */
  // Sales Contacts
  {
    path: "/sales/contacts/overview",
    name: "salesContacts-overview",
    component: () =>
      import("@/views/phases/3_sales/contacts/SalesContacts_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Parent Accounts
  {
    path: "/parentAccounts/overview",
    name: "parentAccounts-overview",
    component: () =>
      import(
        "@/views/phases/3_sales/parentAccounts/ParentAccounts_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/parentAccount/new",
    name: "parentAccounts-new",
    component: () =>
      import("@/views/phases/3_sales/parentAccounts/ParentAccount_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/parentAccount/:id",
    name: "parentAccounts-view",
    component: () =>
      import("@/views/phases/3_sales/parentAccounts/ParentAccount_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Lead Generator
  {
    path: "/leadPool/overview",
    name: "leadPool-overview",
    component: () =>
      import("@/views/phases/3_sales/leadPool/LeadPool_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Accounts
  {
    path: "/accounts/overview",
    name: "accounts-overview",
    component: () =>
      import("@/views/phases/3_sales/accounts/Accounts_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/new",
    name: "accounts-new",
    component: () => import("@/views/phases/3_sales/accounts/Account_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/:id",
    name: "accounts-view",
    component: () => import("@/views/phases/3_sales/accounts/Account_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Action Lists
  {
    path: "/actionLists/overview",
    name: "actionLists-overview",
    component: () =>
      import("@/views/phases/3_sales/actionLists/ActionLists_Overview.vue"),
  },
  {
    path: "/actionList/new",
    name: "actionLists-new",
    component: () =>
      import("@/views/phases/3_sales/actionLists/ActionList_New.vue"),
  },
  {
    path: "/actionList/:id",
    name: "actionLists-view",
    component: () =>
      import("@/views/phases/3_sales/actionLists/ActionList_View.vue"),
  },
  // Account Onboarding
  {
    path: "/accountOnboarding/overview",
    name: "accountOnboardings-overview",
    component: () =>
      import(
        "@/views/phases/3_sales/accountOnboarding/AccountOnboarding_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/accountOnboarding/new",
    name: "accountOnboardings-new",
    component: () =>
      import(
        "@/views/phases/3_sales/accountOnboarding/AccountOnboarding_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/accountOnboarding/:id",
    name: "accountOnboardings-view",
    component: () =>
      import(
        "@/views/phases/3_sales/accountOnboarding/AccountOnboarding_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Budtender Training
  {
    path: "/trainings/overview",
    name: "budtenderTrainings-overview",
    component: () =>
      import(
        "@/views/phases/4_support/budtenderTraining/BudtenderTraining_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/training/new",
    name: "budtenderTrainings-new",
    component: () =>
      import(
        "@/views/phases/4_support/budtenderTraining/BudtenderTraining_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/training/:id",
    name: "budtenderTrainings-view",
    component: () =>
      import(
        "@/views/phases/4_support/budtenderTraining/BudtenderTraining_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },

  // Sales Orders
  {
    path: "/draftOrders/overview",
    name: "draftOrders-overview",
    component: () =>
      import("@/views/phases/3_sales/salesOrders/DraftOrders_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesOrders/overview",
    name: "saleOrders-overview",
    component: () =>
      import("@/views/phases/3_sales/salesOrders/SalesOrders_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesOrder/:brandStatsID/new",
    name: "salesOrder-new",
    component: () =>
      import("@/views/phases/3_sales/salesOrders/SalesOrder_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesOrder/:id",
    name: "salesOrder-view",
    component: () =>
      import("@/views/phases/3_sales/salesOrders/SalesOrder_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Delivery Companies
  {
    path: "/deliveryLogistics/overview",
    name: "deliveryLogistics-overview",
    component: () =>
      import(
        "@/views/phases/3_sales/deliveryLogistics/DeliveryLogistics_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/deliveryLogistic/new",
    name: "deliveryLogistic-new",
    component: () =>
      import(
        "@/views/phases/3_sales/deliveryLogistics/DeliveryLogistic_New.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/deliveryLogistic/:id",
    name: "deliveryLogistic-view",
    component: () =>
      import(
        "@/views/phases/3_sales/deliveryLogistics/DeliveryLogistic_View.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // Order Routes
  {
    path: "/orderRoutes/overview",
    name: "orderRoutes-overview",
    component: () =>
      import("@/views/phases/3_sales/orderRoutes/OrderRoutes_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orderRoute/new",
    name: "orderRoute-new",
    component: () =>
      import("@/views/phases/3_sales/orderRoutes/OrderRoute_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orderRoute/:id",
    name: "orderRoute-view",
    component: () =>
      import("@/views/phases/3_sales/orderRoutes/OrderRoute_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/deliveredOrders/overview",
    name: "deliveredOrders-overview",
    component: () =>
      import("@/views/phases/3_sales/salesOrders/DeliveredOrders_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Licensee Sales
  {
    path: "/licenseeSales/overview",
    name: "licenseeSales-overview",
    component: () =>
      import("@/views/phases/3_sales/licenseeSales/LicenseeSales_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/licenseeSales/new",
    name: "licenseeSales-new",
    component: () =>
      import("@/views/phases/3_sales/licenseeSales/LicenseeSales_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  // Discounts
  {
    path: "/discounts/overview",
    name: "discounts-overview",
    component: () =>
      import("@/views/phases/3_sales/discounts/Discounts_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/discount/new",
    name: "discounts-new",
    component: () =>
      import("@/views/phases/3_sales/discounts/Discount_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/discount/:id",
    name: "discounts-view",
    component: () =>
      import("@/views/phases/3_sales/discounts/Discount_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Phase 4 - Events */
  // Support Contacts
  {
    path: "/support/contacts/overview",
    name: "supportContacts-overview",
    component: () =>
      import("@/views/phases/4_support/contacts/SupportContacts_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Promotions
  {
    path: "/promotions/overview",
    name: "promotions-overview",
    component: () =>
      import("@/views/phases/4_support/promotions/Promotions_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/promotion/new",
    name: "promotions-new",
    component: () =>
      import("@/views/phases/4_support/promotions/Promotion_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/promotion/:id",
    name: "promotions-view",
    component: () =>
      import("@/views/phases/4_support/promotions/Promotion_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Event Staffing
  {
    path: "/eventStaffing/overview",
    name: "eventStaffing-overview",
    component: () =>
      import(
        "@/views/phases/4_support/eventStaffing/EventStaffing_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventStaffing/new",
    name: "eventStaffing-new",
    component: () =>
      import("@/views/phases/4_support/eventStaffing/EventStaffing_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/eventStaffing/:id",
    name: "eventStaffing-view",
    component: () =>
      import("@/views/phases/4_support/eventStaffing/EventStaffing_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Store Events
  {
    path: "/storeEvents/overview",
    name: "storeEvents-overview",
    component: () =>
      import("@/views/phases/4_support/storeEvents/StoreEvents_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/storeEvent/new",
    name: "storeEvents-new",
    component: () =>
      import("@/views/phases/4_support/storeEvents/StoreEvent_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/storeEvent/:id",
    name: "storeEvents-view",
    component: () =>
      import("@/views/phases/4_support/storeEvents/StoreEvent_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Support Tickets
  {
    path: "/supportTickets/overview",
    name: "supportTickets-overview",
    component: () =>
      import(
        "@/views/phases/4_support/supportTickets/SupportTickets_Overview.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/supportTicket/new",
    name: "supportTickets-new",
    component: () =>
      import("@/views/phases/4_support/supportTickets/SupportTicket_New.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/supportTicket/:id",
    name: "supportTickets-view",
    component: () =>
      import("@/views/phases/4_support/supportTickets/SupportTicket_View.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vipClub/overview",
    name: "vipClub-overview",
    component: () =>
      import("@/views/phases/4_support/vipClub/VIPClub_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  /* Phase 5 - Reporting & Analytics */
  {
    path: "/reporting/overview",
    name: "reports-overview",
    component: () => import("@/views/phases/5_reporting/Reports_Overview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/admin/tasks",
    name: "admin-tasks-report",
    component: () =>
      import("@/views/phases/5_reporting/0_admin/AdminTasks_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/admin/expenses",
    name: "admin-marketingExpenses-report",
    component: () =>
      import("@/views/phases/5_reporting/0_admin/AdminExpenses_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/admin/projects",
    name: "admin-projects-report",
    component: () =>
      import("@/views/phases/5_reporting/0_admin/AdminProjects_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/admin/repProductivity",
    name: "admin-repProductivity-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/0_admin/AdminRepProductivity_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/admin/notifications",
    name: "admin-notifications-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/0_admin/AdminNotifications_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/admin/futures",
    name: "admin-futures-report",
    component: () =>
      import("@/views/phases/5_reporting/0_admin/AdminFutures_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sourcing/vendors",
    name: "sourcing-vendors-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/1_sourcing/SourcingVendors_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sourcing/customProductCategories",
    name: "sourcing-customProductCategories-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/1_sourcing/SourcingCustomProductCategories_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sourcing/orders",
    name: "sourcing-orders-report",
    component: () =>
      import("@/views/phases/5_reporting/1_sourcing/SourcingOrders_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sourcing/deposits",
    name: "sourcing-deposits-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/1_sourcing/SourcingDeposits_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sourcing/shipments",
    name: "sourcing-shipments-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/1_sourcing/SourcingShipments_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sourcing/shipEngine",
    name: "sourcing-shipEngine-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/_external/shipengine/SourcingShipEngine_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/manufacturing/workOrders",
    name: "manufacturing-workOrders-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/2_manufacturing/WorkOrders_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/manufacturing/inventory",
    name: "manufacturing-inventory-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/2_manufacturing/LocationInventory_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/manufacturing/rawMaterialOrders",
    name: "manufacturing-rawMaterialOrders-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/2_manufacturing/RawMaterialOrders_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/manufacturing/coa",
    name: "manufacturing-coa-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/2_manufacturing/COAManagement_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/leaderboard",
    name: "sales-leaderboard-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesLeaderboard_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/score",
    name: "sales-score-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesRepScore_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/accounts",
    name: "sales-accounts-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesAccounts_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/map",
    name: "sales-map-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesMap_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/accountPricing",
    name: "sales-accountPricing-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/3_sales/SalesAccountPricing_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/brandMetrics",
    name: "sales-brandMetrics-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesBrandMetrics_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/brandMatrix",
    name: "sales-brandMatrix-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesBrandMatrix_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/closes",
    name: "sales-closes-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesCloses_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/onboarding",
    name: "sales-onboardings-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/3_sales/SalesAccountOnboarding_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/actionLists",
    name: "sales-actionLists-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesActionLists_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/tasks",
    name: "sales-tasks-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesTasks_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/mtd",
    name: "sales-brandmtd-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesMTDSales_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/orders",
    name: "sales-orders-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesOrders_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/historicalStats",
    name: "sales-historicalStats-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesHistorical_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/traceOrders",
    name: "sales-traceOrders-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesTraceOrders_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/ordersByAccount",
    name: "sales-ordersByAccount-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/3_sales/SalesOrdersByAccount_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/postDiscounts",
    name: "sales-postDiscount-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/3_sales/SalesPostDiscountOrders_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/products",
    name: "sales-products-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesProducts_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/deliveryLogistics",
    name: "sales-deliveryLogistics-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/3_sales/SalesDeliveryLogistics_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/ar",
    name: "sales-ar-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesAR_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/payments",
    name: "sales-payments-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesPayments_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/credits",
    name: "sales-credits-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesCredits_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/tpva",
    name: "sales-tpva-report",
    component: () =>
      import("@/views/phases/5_reporting/3_sales/SalesTPVA_Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/support/storeEvents",
    name: "support-storeEvents-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/4_support/Support_StoreEvents_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/support/trainings",
    name: "support-trainings-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/4_support/Support_Trainings_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/support/supportTickets",
    name: "support-supportTickets-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/4_support/Support_SupportTickets_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  // External
  {
    path: "/reporting/manufacturing/leaflinkInventory",
    name: "manufacturing-leaflink-inventory-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/_external/leaflink/LeafLinkInventory_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/leaflink_orders",
    name: "leaflink-orders-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/_external/leaflink/LeaflinkOrders_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/sales/leaflink_reservedInventory",
    name: "leaflink-reservedInventory-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/_external/leaflink/LeaflinkReservedOrderInventory_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/apextrading/brands",
    name: "apex-brands-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/_external/apextrading/ApexTrading_Brands_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/storeEvents/report",
    name: "storeEvents-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/_external/salesforce/StoreEvents_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporting/validator/activations",
    name: "validatorActivations-report",
    component: () =>
      import(
        "@/views/phases/5_reporting/_external/validator/ValidatorActivations_Report.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth.currentUser) {
      let userViews = store.getters.getPhaseViews
        .filter((phaseView) =>
          store.getters.getUser.views.includes(phaseView.value)
        )
        .flatMap((phaseView) => phaseView.views);

      let phaseRoutes = store.getters.getPhases
        .filter(
          (phase) =>
            phase.route !== null &&
            store.getters.getUser.views.includes(phase.routeValue)
        )
        .flatMap((phase) => phase.views);

      let defaultViews = [
        "home",
        "contacts-new",
        "contacts-view",
        "saleOrders-overview",
        "salesOrder-new",
        "salesOrder-view",
        "promotionCredit-view",
        "storeEvents-view",
      ];

      // if (['accountExecutive', 'brandAmbassador', 'accountManager'].includes(store.getters.getUser.role)) {
      //   defaultViews.push('storeEvents-view')
      // }

      let authorizedViews = userViews.concat(phaseRoutes).concat(defaultViews);
      if (
        store.getters.getUser.accountType >= 4 ||
        authorizedViews.includes(to.name)
      ) {
        next();
      } else {
        next({
          name: "403",
        });
      }
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});

export default router;
