import {
  // Auth
  auth,
  // Firestore
  firestore,
  collection,
  getDocs,
  documentId,
  query,
  where,
} from "@/firebase/init";
import moment from "moment";

const state = {
  appName: "mytrace",
  phases: [
    {
      text: "admin",
      value: "admin",
      icon: "mdi-shield-account",
      desc: "General system usage, Project & Data Management",
      vendorDesc: "",
      users: ["admin"],
      sort: 0,
      phase: 0,
      vendorLogin: false,
    },
    {
      text: "sourcing",
      value: "sourcing",
      icon: "mdi-source-fork",
      desc: "Define brands/products & warehouse locations. Create Vendor Orders for product components to be manufactured in Work Orders",
      vendorDesc: "",
      users: ["admin", "fulfillment", "accounting"],
      sort: 1,
      phase: 1,
      vendorLogin: false,
    },
    {
      text: "manufacturing",
      value: "manufacturing",
      icon: "mdi-hammer-wrench",
      desc: "Define raw materials/mixing formulas & schedule Work Orders for manufacturing runs",
      vendorDesc: "",
      users: ["admin", "manufacturing", "accounting"],
      sort: 2,
      phase: 2,
      vendorLogin: false,
    },
    {
      text: "sales",
      value: "sales",
      icon: "mdi-cash-multiple",
      desc: "Manage leads/accounts, complete account onboardings and create/fulfill sales orders",
      vendorDesc: "",
      users: ["admin", "sales", "fulfillment", "accounting"],
      sort: 3,
      phase: 3,
      vendorLogin: false,
    },
    {
      text: "support",
      value: "events",
      icon: "mdi-face-agent",
      desc: "Grow relationships with accounts through store promotions & complete exchanges with stores/customers",
      vendorDesc:
        "Create a login for a brand ambassador from a event staffing company.",
        users: ["admin", "sales", "support"],
      sort: 4,
      phase: 4,
      vendorLogin: true,
    },
    {
      text: "trace reports",
      value: "reports",
      icon: "mdi-file-chart",
      sort: 5,
      phase: 5,
      desc: "View tailored 'Trace' reports using data points from the modules above (Inventory runway/Sales performance/Store promotion results...)",
      vendorLogin: false,
      route: "/reporting/overview",
      routeText: "Reports",
      routeValue: "reports-overview",
      views: [
        "reports-overview",
        "admin-tasks-report",
        "admin-projects-report",
        "admin-repProductivity-report",
        "admin-notifications-report",
        "admin-futures-report",
        "admin-marketingExpenses-report",
        "sourcing-vendors-report",
        "sourcing-customProductCategories-report",
        "sourcing-orders-report",
        "sourcing-deposits-report",
        "sourcing-shipments-report",
        "sourcing-shipEngine-report",
        "manufacturing-workOrders-report",
        "manufacturing-inventory-report",
        "manufacturing-rawMaterialOrders-report",
        "manufacturing-coa-report",
        "sales-leaderboard-report",
        "sales-score-report",
        "sales-accounts-report",
        "sales-map-report",
        "sales-brandMetrics-report",
        "sales-accountPricing-report",
        "sales-closes-report",
        "sales-onboardings-report",
        "sales-actionLists-report",
        "sales-tasks-report",
        "sales-brandmtd-report",
        "sales-orders-report",
        "sales-ordersByAccount-report",
        "sales-traceOrders-report",
        "sales-historicalStats-report",
        "sales-postDiscount-report",
        "sales-products-report",
        "sales-deliveryLogistics-report",
        "sales-ar-report",
        "sales-payments-report",
        "sales-credits-report",
        "sales-tpva-report",
        "support-storeEvents-report",
        "support-trainings-report",
        "support-supportTickets-report",
        // "manufacturing-leaflink-inventory-report",
        // "leaflink-orders-report",
        // "leaflink-reservedInventory-report",
      ],
    },
  ],
  phaseViews: [
    // Admin
    {
      text: "operating zones",
      value: "operatingZones",
      desc: "Toggle states in your mytrace instance. (Active in AZ/CA/NY...)",
      views: [
        "operatingZones-overview",
        "country-new",
        "country-view",
        "state-new",
        "state-view",
        "territory-new",
        "territory-view",
      ],
      icon: "mdi-earth",
      sort: 0,
      phase: 0,
      route: "/operatingZones/overview",
    },
    {
      text: "users",
      value: "users",
      desc: "Define users & their views/permissions in mytrace. Individual views/report visibility can be set on a per user basis",
      views: ["users", "user-new", "user-view"],
      icon: "mdi-account-group",
      sort: 1,
      phase: 0,
      route: "/users/overview",
    },
    {
      text: "vendor logins",
      value: "thirdPartyLogins",
      desc: "Create users with minimal permissions (Create a login for a brand ambassador who only needs to see their assigned store events)",
      views: ["vendorLogins-overview", "vendorLogin-new", "vendorLogin-view"],
      icon: "mdi-badge-account",
      sort: 2,
      phase: 0,
      route: "/vendorLogins/overview",
    },
    {
      text: "data management",
      value: "dataManager",
      desc: "Import/Export data for your mytrace instance (import a list of accounts from a spreadsheet for use in the 'Accounts' view)",
      views: [
        "dataManagement-overview",
        "dataManagement-file-excel",
        "dataManagement-mytrace-account",
        "dataManagement-mytrace-account-transfer",
        "dataManagement-mytrace-account-export",
        "dataManagement-mytrace-aggregate-sales",
        "dataManagement-salesforce-accounts",
        "dataManagement-salesforce-contacts",
        "dataManagement-salesforce-tasks",
        "dataManagement-salesforce-storeEvents",
        "dataManagement-salesforce-budtenderTrainings",
        "dataManagement-salesforce-final",
        "dataManagement-leaflink-accounts",
        "dataManagement-leaflink-salesOrders",
      ],
      icon: "mdi-database-sync",
      sort: 3,
      phase: 0,
      route: "/dataManagement/overview",
    },
    {
      text: "integrations",
      value: "integrations",
      desc: "Toggle integrations to extend the functionality of your mytrace instance",
      views: ["integrations-overview"],
      icon: "mdi-dip-switch",
      sort: 4,
      phase: 0,
      route: "/integrations/overview",
    },
    {
      text: "tasks",
      value: "tasks",
      desc: "Personalized task manager for the current user. Tasks related to a specific account should be created within the 'Account' profile",
      views: ["tasks", "task-new", "task-view"],
      icon: "mdi-file-tree",
      sort: 5,
      phase: 0,
      route: "/tasks/overview",
    },
   
    {
      text: "project templates",
      value: "projectTemplates",
      desc: "Set a pre-defined set of tasks for use within a project (Every product launch has Task A/B/C...)",
      views: [
        "projectTemplates",
        "projectTemplates-new",
        "projectTemplates-view",
      ],
      icon: "mdi-format-list-group-plus",
      sort: 7,
      phase: 0,
      route: "/projectTemplates/overview",
    },
    {
      text: "projects",
      value: "projects",
      desc: "Tasks can be defined & assigned to users. A project cannot be completed until all related tasks are complete",
      views: ["projects-overview", "projects-new", "projects-view"],
      icon: "mdi-chart-gantt",
      sort: 8,
      phase: 0,
      route: "/projects/overview",
    },
    {
      text: "QR templates",
      value: "qrTemplates",
      desc: "Set a pre-defined template for use with the QR code generator",
      views: [
        "qrTemplates",
        "qrTemplates-new",
        "qrTemplates-view",
      ],
      icon: "mdi-qrcode",
      sort: 9,
      phase: 0,
      route: "/qrTemplates/overview",
    },
    {
      text: "QR codes",
      value: "qrCodes",
      desc: "Create a dynamic QR Code based on a QR code templaate",
      views: [
        "qrCodes",
        "qrCodes-new",
        "qrCodes-view",
      ],
      icon: "mdi-qrcode-scan",
      sort: 9,
      phase: 0,
      route: "/qrCodes/overview",
    },
    // {
    //   text: "email templates",
    //   value: "emailTemplates-overview",
    //   views: [
    //     "emailTemplates-overview",
    //     "emailTemplate-new",
    //     "emailTemplate-view",
    //   ],
    //   icon: "mdi-email-newsletter",
    //   sort: 9,
    //   phase: 0,
    //   route: "/emailTemplates/overview",
    // },
    // {
    //   text: "emails",
    //   value: "emails",
    //   views: ["emails"],
    //   icon: "mdi-email-multiple",
    //   sort: 10,
    //   phase: 0,
    //   route: "/emails/overview",
    // },
    // Sourcing
    {
      text: "vendors",
      value: "vendors",
      desc: "Define a list of vendors for use with Vendor/Custom Orders",
      views: ["vendors-overview", "vendors-new", "vendors-view"],
      icon: "mdi-folder-account",
      sort: 0,
      phase: 1,
      route: "/vendors/overview",
    },
    {
      text: "retail brands",
      value: "brandsOverview",
      desc: "'Retail' brands are in-house brands you fully manage. These brands are used with Vendor/Sales orders",
      views: [
        "brands-overview",
        "brand-new",
        "brand-view",
        "payableTo-new",
        "payableTo-view",
        "priceOverride-view",
      ],
      icon: "mdi-store",
      sort: 1,
      phase: 1,
      route: "/brands/overview",
    },
    {
      text: "retail brand products",
      value: "products",
      desc: "View a list of product categories/products for retail brands",
      views: [
        "products-overview",
        "category-new",
        "category-view",
        "products-new",
        "products-view",
      ],
      icon: "mdi-sitemap",
      sort: 2,
      phase: 1,
      route: "/products/overview/",
    },
    {
      text: "white label brands",
      value: "whiteLabelBrands",
      desc: "White label brands are 3rd parties you manufacture for. The 'testing' steps are skipped on white label work orders",
      views: [
        "whiteLabelBrands-overview",
        "whiteLabelBrand-new",
        "whiteLabelBrand-view",
      ],
      icon: "mdi-package-variant",
      sort: 3,
      phase: 1,
      route: "/whiteLabelBrands/overview",
    },
    {
      text: "white label products",
      value: "whiteLabelProducts",
      desc: "View a list of products that are filtered specifically to white label brands",
      views: [
        "whiteLabelProducts-overview",
        "category-new",
        "category-view",
        "products-new",
        "products-view",
      ],
      icon: "mdi-sitemap",
      sort: 4,
      phase: 1,
      route: "/whiteLabelProducts/overview/",
    },
    {
      text: "locations",
      value: "locations",
      desc: "Define warehouses within a state for inventory management & manufacture products with 'Work Orders'",
      views: [
        "locations-overview",
        "locations-new",
        "locations-view",
        "location-rawMaterials-overview",
        "location-terpenes-overview",
        "inventory-reconcile-overview",
        "transfer-inventory-overview",
        "new-transfer-location-select",
        "transfer-assembled-new",
        "transfer-assembled-view",
        "transfer-components-new",
        "transfer-components-view",
        "transfer-rawMaterials-new",
        "transfer-rawMaterials-view",
        "manufacturing-overview",
        "workOrder-brand-new",
        "new-workOrder-products",
        "new-workOrder-review",
        "mainWorkOrder-view",
        "productWorkOrder-view",
        "location-white-label-orders-overview",
        "whiteLabelOrder-new-select",
        "whiteLabelOrder-new-review",
        "whiteLabelInvoice-view",
      ],
      icon: "mdi-warehouse",
      sort: 5,
      phase: 1,
      route: "/locations/overview",
    },
    {
      text: "vendor orders",
      value: "vendorOrders",
      desc: "The products on a vendor order will be added as 'incoming' inventory to a location. Vendor orders can only be created with 'Retail' brands",
      views: [
        "vendorOrders-overview",
        "vendorOrder-brand-new",
        "vendorOrder-products-new",
        "vendorOrder-review-new",
        "vendorOrder-master-view",
        "vendorOrder-productCategory-view",
        "vendorOrder-invoice-view",
      ],
      icon: "mdi-truck",
      sort: 6,
      phase: 1,
      route: "/vendorOrders/overview",
    },
    {
      text: "custom product categories",
      value: "customProducts",
      desc: "Define a list of product categories/products used with Custom orders",
      views: [
        "customProducts-overview",
        "customProducts-new",
        "customProducts-view",
      ],
      icon: "mdi-sitemap",
      sort: 7,
      phase: 1,
      route: "/customProducts/overview",
    },
    {
      text: "custom orders",
      value: "customOrders",
      desc: "Custom orders are meant for purchases that do not need their inventory tracked (5K business cards were purchased from X vendor)",
      views: ["customOrders-overview", "customOrder-new", "customOrder-view"],
      icon: "mdi-clipboard-list",
      sort: 8,
      phase: 1,
      route: "/customOrders/overview",
    },
    // Manufacturing
    {
      text: "testing labs",
      value: "testingLabs",
      desc: "Testing labs are used within a work order during the 'testing' steps",
      views: ["testingLabs-overview", "testingLabs-new", "testingLabs-view"],
      icon: "mdi-test-tube",
      sort: 0,
      phase: 2,
      route: "/testingLabs/overview",
    },
    {
      text: "terpenes",
      value: "terpenes",
      desc: "Terpenes are used within the 'Mix Calculator' & 'Work Orders'",
      views: ["terpenes-overview", "terpenes-new", "terpenes-view"],
      icon: "mdi-water",
      sort: 1,
      phase: 2,
      route: "/terpenes/overview",
    },
    {
      text: "raw materials",
      value: "rawMaterials",
      desc: "Raw material batches can be purchased with a 'Raw material order' & managed in a location during a work order",
      views: ["rawMaterials-overview", "rawMaterials-new", "rawMaterials-view"],
      icon: "mdi-cannabis",
      sort: 2,
      phase: 2,
      route: "/rawMaterials/overview",
    },
    {
      text: "product recipes",
      value: "mixCalculator",
      desc: "Define product 'recipes' to calculate the needed raw materials/terpenes during a work order based on the QTY being manufactured",
      views: ["mixCalculator-overview", "productRecipes-edit"],
      icon: "mdi-tune-vertical",
      sort: 3,
      phase: 2,
      route: "/mixCalculator/overview",
    },
    {
      text: "raw material vendors",
      value: "rawMaterialVendors",
      desc: "A Raw material vendor supplies 'raw materials' for use within a raw material order. A menu of what they offer can be defined",
      views: [
        "rawMaterialVendors-overview",
        "rawMaterialVendors-new",
        "rawMaterialVendors-view",
      ],
      icon: "mdi-power-plug",
      sort: 4,
      phase: 2,
      route: "/rawMaterialVendors/overview",
    },
    {
      text: "raw material orders",
      value: "rawMaterialOrders",
      desc: "The raw material batches purchased from these orders are added to the location inventory. The related work orders that use the raw materials from these orders will be linked",
      views: [
        "rawMaterialOrders-overview",
        "rawMaterialOrder-new",
        "rawMaterialOrders-view",
      ],
      icon: "mdi-clipboard-list",
      sort: 5,
      phase: 2,
      route: "/rawMaterialOrders/overview",
    },
    {
      text: "COAs",
      value: "coa",
      desc: "COAs are added after testing is completed during a work order. COAs will be shown within a sales order based on the line items",
      views: ["coa-overview", "coa-new", "coa-view"],
      icon: "mdi-newspaper",
      sort: 6,
      phase: 2,
      route: "/coa/overview",
    },
    {
      text: "white label clients",
      value: "whiteLabelClients",
      desc: "Define a list of white label clients when creating invoices for white label orders",
      views: [
        "whiteLabelClients-overview",
        "whiteLabelClients-new",
        "whiteLabelClients-view",
      ],
      icon: "mdi-package",
      sort: 7,
      phase: 2,
      route: "/whiteLabelClients/overview",
    },
    // Sales
    {
      text: "sales contacts",
      value: "salesContacts",
      desc: "View a rolodex of contacts from the 'Sales' module, filtered by title",
      views: ["salesContacts-overview"],
      icon: "mdi-contacts",
      sort: 0,
      phase: 3,
      route: "/sales/contacts/overview",
    },
    {
      text: "lead pool",
      value: "leadPool",
      desc: "Pull a list of accounts from external sources where the 'Lead Pool' field is active",
      views: ["leadPool-overview"],
      icon: "mdi-account-search",
      sort: 1,
      phase: 3,
      route: "/leadPool/overview",
    },
    {
      text: "parent accounts",
      value: "parentAccounts",
      desc: "A 'parent' account is used to group a list of all related accounts",
      views: [
        "parentAccounts-overview",
        "parentAccounts-new",
        "parentAccounts-view",
      ],
      icon: "mdi-domain",
      sort: 2,
      phase: 3,
      route: "/parentAccounts/overview",
    },
    {
      text: "accounts",
      value: "accounts",
      desc: "A list of accounts (Storefront/Delivery/Distro/Smoke shop) in your mytrace instance. Individual accounts can be searched for with the 'Search Accounts' field",
      views: ["accounts-overview", "accounts-new", "accounts-view"],
      icon: "mdi-folder-account",
      sort: 3,
      phase: 3,
      route: "/accounts/overview",
    },
    {
      text: "action lists",
      value: "action-lists",
      desc: "Create a list of accounts to call based on conditions (Follow up with all accounts that haven't had an order within 90+ days)",
      views: ["actionLists-overview", "actionLists-new"],
      icon: "mdi-list-status",
      sort: 4,
      phase: 3,
      route: "/actionLists/overview",
    },
    {
      text: "account onboarding",
      value: "account-onboarding",
      desc: "When an account becomes 'active', an onboarding with tasks to schedule training/staff samples/a store event/intro email ",
      views: [
        "accountOnboardings-overview",
        "accountOnboardings-new",
        "accountOnboardings-view",
      ],
      icon: "mdi-timeline-check",
      sort: 5,
      phase: 3,
      route: "/accountOnboarding/overview",
    },
    {
      text: "discounts",
      value: "discounts",
      desc: "Define discounts for a brand. Can be used to set case discounts (1 case = -$1/unit)",
      views: ["discounts-overview", "discounts-new", "discounts-view"],
      icon: "mdi-sale",
      sort: 7,
      phase: 3,
      route: "/discounts/overview",
    },
    {
      text: "draft orders",
      value: "draftOrders",
      desc: "Draft orders are sales orders that have not been submitted & no inventory is reserved yet. Draft orders can also be utilized for backorders",
      views: ["draftOrders-overview"],
      icon: "mdi-clipboard-text",
      sort: 8,
      phase: 3,
      route: "/draftOrders/overview",
    },
    {
      text: "sales orders",
      value: "salesOrders",
      desc: "All active, in-process orders. When an order is delivered, it will be within 'Delivered' orders view",
      views: ["saleOrders-overview", "salesOrder-new", "salesOrder-view"],
      icon: "mdi-clipboard-list",
      sort: 9,
      phase: 3,
      route: "/salesOrders/overview",
    },
    {
      text: "delivery/logistics",
      value: "deliveryLogistics",
      desc: "Define delivery companies & set their schedule based on day/counties (Delivers to X county on Y day)",
      views: [
        "deliveryLogistics-overview",
        "deliveryLogistic-new",
        "deliveryLogistic-view",
      ],
      icon: "mdi-truck",
      sort: 10,
      phase: 3,
      route: "/deliveryLogistics/overview",
    },
    {
      text: "delivery routes",
      value: "orderRoutes",
      desc: "Create an optimized delivery route for in-progress orders based on their ship date",
      views: ["orderRoutes-overview", "orderRoute-new", "orderRoute-view"],
      icon: "mdi-map-marker-distance",
      sort: 11,
      phase: 3,
      route: "/orderRoutes/overview",
    },
    {
      text: "delivered orders",
      value: "deliveredOrders",
      desc: "A list of all delivered orders with an open balance. A breakdown of these balances can be seen between COD/Net orders",
      views: ["deliveredOrders-overview"],
      icon: "mdi-cash-marker",
      sort: 12,
      phase: 3,
      route: "/deliveredOrders/overview",
    },
    {
      text: "licensee sales",
      value: "licenseeSales",
      desc: "Submit sales/units sold for 3rd party companies that may license your brand, even if they don't use mytrace",
      views: [
        "licenseeSales-overview",
        "licenseeSales-new",
        "licenseeSales-view",
      ],
      icon: "mdi-calendar-check",
      sort: 13,
      phase: 3,
      route: "/licenseeSales/overview",
    },
    // Support
    {
      text: "support contacts",
      value: "supportContacts",
      desc: "A list of contacts from the 'Support' module. These contacts are mainly from the 'Support Tickets' view",
      views: ["supportContacts-overview"],
      icon: "mdi-contacts",
      sort: 0,
      phase: 4,
      route: "/support/contacts/overview",
    },
    {
      text: "promotions",
      value: "promotions-overview",
      desc: "Define a list of promotions for use within 'Store Events'. The Related product categories of this promo can also be set",
      views: ["promotions-overview", "promotions-new", "promotions-view"],
      icon: "mdi-sale",
      sort: 1,
      phase: 4,
      route: "/promotions/overview",
    },
    {
      text: "event staffing",
      value: "eventStaffing-overview",
      desc: "Define 3rd party staffing companies. A 'Vendor Login' can be created from the contacts in this section",
      views: [
        "eventStaffing-overview",
        "eventStaffing-new",
        "eventStaffing-view",
      ],
      icon: "mdi-briefcase-account",
      sort: 2,
      phase: 4,
      route: "/eventStaffing/overview",
    },
    {
      text: "store events",
      value: "events-overview",
      desc: "Store Events are used to track promotions within a store. (Can be set as in-person or a ghost promo)",
      views: ["storeEvents-overview", "storeEvents-new", "storeEvents-view"],
      icon: "mdi-calendar",
      sort: 3,
      phase: 4,
      route: "/storeEvents/overview",
    },
    {
      text: "trainings",
      value: "budtender-training",
      desc: "A training is used to educate the staff of an account about the brand. Training types can be defined as in person/remote, 1:1/team, etc..",
      views: [
        "budtenderTrainings-overview",
        "budtenderTrainings-new",
        "budtenderTrainings-view",
      ],
      icon: "mdi-school",
      sort: 4,
      phase: 4,
      route: "/trainings/overview",
    },
    {
      text: "support tickets",
      value: "supportTickets-overview",
      desc: "Support tickets are used for managing exchanges with a customer or store",
      views: [
        "supportTickets-overview",
        "supportTickets-new",
        "supportTickets-view",
      ],
      icon: "mdi-briefcase",
      sort: 5,
      phase: 4,
      route: "/supportTickets/overview",
    },
    {
      text: "VIP club",
      value: "vipClub-overview",
      views: ["vipClub-overview"],
      icon: "mdi-account-star-outline",
      sort: 5,
      phase: 4,
      route: "/vipClub/overview",
    },
  ],
  reports: [
    {
      text: "tasks",
      to: "admin-tasks-report",
      desc: "View pending/complete tasks, filter by state & department",
      icon: "mdi-file-tree",
      type: "admin",
    },
    {
      text: "projects",
      to: "admin-projects-report",
      desc: "View status of project(s) by selected date range",
      icon: "mdi-chart-gantt",
      type: "admin",
    },
    {
      text: "rep productivity",
      to: "admin-repProductivity-report",
      desc: "View tasks/closes & orders across all reps for specified date range",
      icon: "mdi-account-group",
      type: "admin",
    },
    {
      text: "notifications",
      to: "admin-notifications-report",
      desc: "View all notifications sent in mytrace based on a selected date range & filter read vs. unread",
      icon: "mdi-bell",
      type: "admin",
    },
    {
      text: "futures",
      to: "admin-futures-report",
      desc: "Upcoming cash flow needed based on pending vendor/custom/raw material order(s)",
      icon: "mdi-timeline-clock",
      type: "admin",
    },
    {
      text: "vendors",
      to: "sourcing-vendors-report",
      desc: "Vendor/Custom order(s) filtered by selected vendor",
      icon: "mdi-folder-account",
      type: "sourcing",
    },
    {
      text: "custom product category orders",
      to: "sourcing-customProductCategories-report",
      desc: "View custom orders filtered by selected custom product category",
      icon: "mdi-sitemap",
      type: "sourcing",
    },
    {
      text: "vendor/custom orders",
      to: "sourcing-orders-report",
      desc: "Pending/Complete Vendor/Custom orders within selected date range",
      icon: "mdi-truck",
      type: "sourcing",
    },
    {
      text: "tracking numbers & shipments",
      to: "sourcing-shipments-report",
      desc: "View & update tracking numbers for Vendor/Custom orders",
      icon: "mdi-package-variant-closed",
      type: "sourcing",
    },
    // {
    //   text: "track shipments with ShipEngine",
    //   to: "sourcing-shipEngine-report",
    //   desc: "View & update tracking numbers with the ShipEngine integration",
    //   icon: "mdi-shipping-pallet",
    //   type: "sourcing",
    // },
    {
      text: "deposits",
      to: "sourcing-deposits-report",
      desc: "Payments made towards Vendor/Custom orders",
      icon: "mdi-cash",
      type: "sourcing",
    },
    {
      text: "work orders",
      to: "manufacturing-workOrders-report",
      desc: "Work orders at fulfillment location by selected date range",
      icon: "mdi-beaker",
      type: "manufacturing",
    },
    {
      text: "inventory",
      to: "manufacturing-inventory-report",
      desc: "Assembled/Component inventory across selected state. Includes work & vendor order(s) to show what's being manufactured or incoming",
      icon: "mdi-sitemap",
      type: "manufacturing",
    },
    {
      text: "raw material orders",
      to: "manufacturing-rawMaterialOrders-report",
      desc: "View raw materials purchased, grouped by raw material type(s) & vendor",
      icon: "mdi-clipboard-list",
      type: "manufacturing",
    },
    {
      text: "coa management",
      to: "manufacturing-coa-report",
      desc: "Search, filter & update COAs based on expiration date",
      icon: "mdi-newspaper",
      type: "manufacturing",
    },
    {
      text: "leaderboard",
      to: "sales-leaderboard-report",
      desc: "Compare active sales reps across metrics like orders/tasks/closes/collections/store events/trainings",
      icon: "mdi-crown",
      type: "sales",
      salesRepDashboard: true,
    },
    {
      text: "rep score",
      to: "sales-score-report",
      desc: "Score card of sales rep (tasks/accounts/orders/store events/trainings)",
      icon: "mdi-trophy-award",
      type: "sales",
    },
    {
      text: "accounts",
      to: "sales-accounts-report",
      desc: "All accounts within selected state",
      icon: "mdi-folder-account",
      type: "sales",
    },
    {
      text: "account metrics",
      to: "sales-brandMetrics-report",
      desc: "Growth of brand within account, product trends, total promotion",
      icon: "mdi-chart-areaspline",
      type: "sales",
    },
    // {
    //   text: "account/brand matrix",
    //   to: "sales-brandMatrix-report",
    //   desc: "Compare all active retail brand(s) against all related accounts in the selected state",
    //   icon: "mdi-matrix",
    //   type: "sales",
    // },
    {
      text: "account map",
      to: "sales-map-report",
      desc: "Visualize a map of account(s). Filter by status/managing staff...",
      icon: "mdi-map",
      type: "sales",
    },
    {
      text: "account pricing",
      to: "sales-accountPricing-report",
      desc: "View a list of accounts with special pricing",
      icon: "mdi-card-account-details-star",
      type: "sales",
    },
    {
      text: "closes",
      to: "sales-closes-report",
      desc: "View all accounts closed within a selected date range",
      icon: "mdi-party-popper",
      type: "sales",
    },
    {
      text: "account onboardings",
      to: "sales-onboardings-report",
      desc: "Pending/Complete onboarding(s), calculates AVG completion time",
      icon: "mdi-timeline-check",
      type: "sales",
    },
    {
      text: "action lists",
      to: "sales-actionLists-report",
      desc: "View results of assigned task lists by selected date range",
      icon: "mdi-list-status",
      type: "sales",
    },
    {
      text: "tasks",
      to: "sales-tasks-report",
      desc: "View pending/complete tasks for active sales reps based on a selected date range",
      icon: "mdi-file-tree",
      type: "sales",
    },
    {
      text: "MTD sales",
      to: "sales-brandmtd-report",
      desc: "Month-to-date sales for selected state(s), includes monthly goal & percentage of goal completed",
      icon: "mdi-trending-up",
      type: "sales",
    },
    {
      text: "orders",
      to: "sales-orders-report",
      desc: "Orders created/shipped within selected date range. Orders are assigned to who is currently managing account",
      icon: "mdi-clipboard-list",
      type: "sales",
      salesRepDashboard: true,
    },
    {
      text: "orders (by account)",
      to: "sales-ordersByAccount-report",
      desc: "Show all orders related to selected account(s), with month/month growth",
      icon: "mdi-account-search",
      type: "sales",
    },
    {
      text: "trace orders",
      to: "sales-traceOrders-report",
      desc: "View sales orders based on date of status (Orders in 'submitted' on MM/DD/YYYY)",
      icon: "mdi-clipboard-text-clock",
      type: "sales",
    },
    {
      text: "post discount sales",
      to: "sales-postDiscount-report",
      desc: "View the adjusted revenue of order(s) after filtering out discounts applied",
      icon: "mdi-clipboard-list",
      type: "sales",
    },
    {
      text: "historical stats",
      to: "sales-historicalStats-report",
      desc: "Orders within selected date range. Orders are sorted to who was managing the account at the time the order was submitted",
      icon: "mdi-history",
      type: "sales",
    },
    {
      text: "product trends",
      to: "sales-products-report",
      desc: "Month over Month trends by selected state. Includes delivery counties",
      icon: "mdi-swap-vertical-bold",
      type: "sales",
    },
    {
      text: "delivery/logistics orders",
      to: "sales-deliveryLogistics-report",
      desc: "View orders by selected delivery company. Calculates AVG turnaround time & delivery counties",
      icon: "mdi-truck",
      type: "sales",
    },
    {
      text: "accounts receivable",
      to: "sales-ar-report",
      desc: "Aging report for account(s) with orders with an open balance. Sorted by Within Terms/30/60/90/120+ days past due",
      icon: "mdi-hand-coin",
      type: "sales",
      salesRepDashboard: true,
    },
    {
      text: "payments",
      to: "sales-payments-report",
      desc: "Payments submitted within selected date range. Will calculate rep bonus based on payment received before order is delinquent",
      icon: "mdi-cash",
      type: "sales",
    },
    {
      text: "credits",
      to: "sales-credits-report",
      desc: "View credits within a customizable date range",
      icon: "mdi-seal",
      type: "sales",
    },
    {
      text: "total promotional value analysis (TPVA)",
      to: "sales-tpva-report",
      desc: "Calculate total promo/credits in comparison with sales. Filter accounts by a promo % threshold",
      icon: "mdi-sale",
      type: "sales",
    },
    {
      text: "store events",
      to: "support-storeEvents-report",
      desc: "View store events within selected date range. Leaderboard of top events/trending products",
      icon: "mdi-calendar-multiple",
      type: "support",
    },
    {
      text: "trainings",
      to: "support-trainings-report",
      desc: "Trainings within selected date range. Includes a leaderboard of individualized rep metrics & metrics of trending training type(s)",
      icon: "mdi-school",
      type: "support",
    },
    {
      text: "support tickets",
      to: "support-supportTickets-report",
      desc: "Pending/Complete support tickets, includes a chart of trending product categories being exchanged within ticket(s)",
      icon: "mdi-briefcase",
      type: "support",
    },
  ],
  locationTypes: [
    {
      text: "Main Hub",
      desc: "A warehouse where product(s) are stored. No manufacturing/work orders.",
      value: "mainHub",
    },
    {
      text: "Fulfillment",
      desc: "All of the features of a Main Hub, but raw materials/work orders are available.",
      value: "fulfillment",
    },
  ],
  contactTitles: [
    {
      text: "Owner",
      value: "owner",
    },
    {
      text: "Marketing",
      value: "marketing",
    },
    { text: "Purchasing Manager", value: "purchasing manager" },
    { text: "Store Manager", value: "store manager" },
    { text: "General Manager", value: "general manager" },
    { text: "Inventory Manager", value: "inventory manager" },
    { text: "License Holder", value: "license holder" },
    { text: "Accounting", value: "accounting" },
    { text: "Buyer", value: "buyer" },
    { text: "Budtender", value: "budtender" },
    { text: "Sales", value: "sales" },
    { text: "Events", value: "events" },
    { text: "Brand Ambassador", value: "brand ambassador" },
    { text: "Scheduling Coordinator", value: "scheduling coordinator" },
    { text: "Customer", value: "customer" },
    { text: "Other", value: "other" },
    { text: "(Blank)", value: null },
  ],
  contactPreferences: [
    { text: "Call", value: "call" },
    { text: "Text (SMS)", value: "text" },
    { text: "Email", value: "email" },
  ],
  integrations: [
    {
      name: "Slack",
      purpose: "Direct/Group messaging",
      value: "slack",
      type: "communication",
      requiresConfig: true,
      externalID: false,
      hasAPIEndpoints: false,
    },
    {
      name: "Dialpad",
      purpose: "Calling/Texting",
      value: "dialpad",
      type: "communication",
      requiresConfig: false,
      externalID: false,
      hasAPIEndpoints: false,
    },
    {
      name: "SendGrid",
      purpose: "Email templates",
      value: "sendgrid",
      type: "communication",
      requiresConfig: true,
      externalID: false,
      hasAPIEndpoints: false,
    },
    {
      name: "LeafLink",
      text: "LeafLink",
      purpose: "Cannabis sales ordering platform",
      value: "leaflink",
      type: "sales",
      requiresConfig: true,
      externalID: true,
      hasAPIEndpoints: true,
    },
    {
      name: "Apex Trading",
      text: "Apex Trading",
      purpose: "Cannabis MRP / CRM & sales ordering platform",
      value: "apextrading",
      type: "sales",
      requiresConfig: true,
      externalID: false,
      hasAPIEndpoints: false,
    },
    {
      name: "Chaski",
      text: "Chaski",
      purpose: "Cannabis fulfillment & distro platform",
      value: "chaski",
      type: "sales",
      requiresConfig: true,
      externalID: false,
      hasAPIEndpoints: true,
    },
    {
      name: "ShipEngine",
      purpose:
        "Automate tracking number updates from multiple couriers (UPS/DHL/FedEx...)",
      value: "shipengine",
      type: "shipping",
      requiresConfig: true,
      externalID: false,
      hasAPIEndpoints: false,
    },
    {
      name: "BioTrack",
      purpose: "State compliance software for manifesting orders",
      value: "biotrack",
      type: "compliance",
      requiresConfig: true,
      externalID: false,
      hasAPIEndpoints: false,
    },
    {
      name: "Product Validator",
      purpose:
        "Include a unique validation code per product / create a mailing list of customers",
      value: "validator",
      type: "b2c",
      requiresConfig: false,
      externalID: false,
      hasAPIEndpoints: false,
    },
     {
      name: "VIP Club",
      purpose: "Gather industry contacts through a lead generation form",
      value: "vipClub",
      requiresConfig: false,
      externalID: false,
      hasAPIEndpoints: false,
    },
  ],
  countryCodes: [
    {
      name: "antartica",
      abbreviation: "dt",
      countryCode: "-999",
    },
    {
      name: "united states",
      abbreviation: "us",
      countryCode: "1",
    },
  ],
  fileUploadTypes: [
    { text: "Link to a URL (larger files)", value: "url" },
    { text: "Upload a file", value: "upload" },
  ],
  helpdeskGuides: [
    // Admin
    {
      text: "What do the colors mean in mytrace?",
      desc: "Based on the status of a view, the colors in mytrace will change (cannot submit = blue, ready to submit = black).",
      value: "systemColors",
      icon: "mdi-magnify",
      color: "black",
      phase: 0,
    },
    {
      text: `What do the different user permissions mean?`,
      desc: "What changes between a User => Admin permissions?",
      value: "userPermissions",
      icon: "mdi-account",
      color: "black",
      phase: 0,
    },
    {
      text: `What dashboards are available?`,
      desc: "mytrace has dashboards tailored to every role.",
      value: "dashboards",
      icon: "mdi-monitor-dashboard",
      color: "black",
      phase: 0,
    },
    // Sourcing
    {
      text: "Add a retail brand",
      desc: "Add a new brand",
      value: "addRetailBrand",
      icon: "mdi-store",
      color: "black",
      phase: 1,
    },
    {
      text: "Add a product category",
      desc: "Add a product category to a brand",
      value: "addProductCategory",
      icon: "mdi-sitemap",
      color: "black",
      phase: 1,
    },
    {
      text: "Add a products to a product category",
      desc: "Add new products to an existing product category",
      value: "addProducts",
      icon: "mdi-package-variant",
      color: "black",
      phase: 1,
    },
    {
      text: "Create a new location",
      desc: "Create a new warehouse location",
      value: "addLocation",
      icon: "mdi-warehouse",
      color: "black",
      phase: 1,
    },
    {
      text: "Create a vendor order",
      desc: "Create a new purchase order for product components",
      value: "addVendorOrder",
      icon: "mdi-truck",
      color: "black",
      phase: 1,
    },
    {
      text: "Update a vendor order",
      desc: "Mark product components as received, add payments/tracking numbers",
      value: "updateVendorOrder",
      icon: "mdi-pencil",
      color: "black",
      phase: 1,
    },
    // Manufacturing
    {
      text: "Add a brand/products to a location",
      desc: "Manually add a new brand/product category to a location",
      value: "addBrandToLocation",
      icon: "mdi-package-variant",
      color: "black",
      phase: 2,
    },
    {
      text: "Reconcile inventory",
      desc: "Reconcile the available inventory (assembled/components) for a brand within a location",
      value: "reconcileInventory",
      icon: "mdi-help-network",
      color: "black",
      phase: 2,
    },
    // Sales
    {
      text: "Add an account",
      desc: "Add a new lead/account to mytrace",
      value: "addSalesAccount",
      icon: "mdi-folder-account",
      color: "black",
      phase: 3,
    },
    {
      text: "Update an account",
      desc: "Modify an account's information (Name/Address/Status/Managed by...)",
      value: "updateSalesAccount",
      icon: "mdi-pencil",
      color: "black",
      phase: 3,
    },
    {
      text: "Add contacts to an account",
      desc: "Add contacts (owner/general manager) to an account",
      value: "addSalesContact",
      icon: "mdi-badge-account-horizontal",
      color: "black",
      phase: 3,
    },
    {
      text: "Add tasks to an account",
      desc: "Add tasks (Calls/Meetings...) to an account",
      value: "addSalesTasks",
      icon: "mdi-calendar-check",
      color: "black",
      phase: 3,
    },
    {
      text: "Create a sales order",
      desc: "Create a new order (sales/sample) for an account",
      value: "newSalesOrder",
      icon: "mdi-clipboard-list",
      color: "black",
      phase: 3,
    },
    {
      text: "Sales Order - Process",
      desc: "How are the sales orders organized in mytrace? What do the statuses mean?",
      value: "salesOrderProcess",
      icon: "mdi-help-circle",
      color: "black",
      phase: 3,
    },
    // Support
    {
      text: "Create a new store event",
      desc: "Create a new store event (in-person/ghost/brand presence) for an account",
      value: "newStoreEvent",
      icon: "mdi-calendar-multiple",
      color: "black",
      phase: 4,
    },
    {
      text: "Create a new training",
      desc: "Create a new training (in-person/offline) for an account",
      value: "newTraining",
      icon: "mdi-school",
      color: "black",
      phase: 4,
    },
  ],
  helpdeskInstructions: [
    // Admin - mytrace Colors
    {
      text: "mytrace uses different colors to give visual cues on what to do next:",
      sort: 1,
      related: "systemColors",
      phase: 0,
      action: null,
    },
    {
      text: "Blue: Related to a clickable button or an area to focus on.",
      sort: 2,
      related: "systemColors",
      phase: 0,
      action: null,
    },
    {
      text: "Yellow: Used to signify the field is required & needs to be filled out to move forward.",
      sort: 3,
      related: "systemColors",
      phase: 0,
      action: null,
    },
    {
      text: "Orange: Related to updating content within mytrace. If a field has been updated, the background color will turn orange.",
      sort: 4,
      related: "systemColors",
      phase: 0,
      action: null,
    },
    {
      text: "Green/Red: submit or cancel.",
      sort: 5,
      related: "systemColors",
      phase: 0,
      action: null,
    },
    {
      text: "Black/Grey/White: When there are no further actions needed the header of a card will change from blue => black.",
      sort: 6,
      related: "systemColors",
      phase: 0,
      action: null,
    },
    // Admin - User permissions
    {
      text: "mytrace has different user permissions to control what a user can do:",
      sort: 1,
      related: "userPermissions",
      phase: 0,
      action: null,
    },
    {
      text: `User: least amount of access, will need to request a manager to complete certain actions. Adding "extended user permissions" can grant an individual user access to export data / add payments & similar if they should have some manager permissions but not all.`,
      sort: 2,
      related: "userPermissions",
      phase: 0,
      action: null,
    },
    {
      text: "Manager: Has more permissions than a standard user. Can approve orders, submit payments without needing to use the extended permissions. Like a user, specific views of what they should have visibility of need to be set.",
      sort: 3,
      related: "userPermissions",
      phase: 0,
      action: null,
    },
    {
      text: "Admin: Highest level of access. Will have full visibility of views & permissions.",
      sort: 4,
      related: "userPermissions",
      phase: 0,
      action: null,
    },
    {
      text: `*If a user has the "Can only see assigned state/location" option set, they will not be able to see states/providences outside of what they are assigned to. (A user in California can only see California related data)`,
      sort: 5,
      related: "userPermissions",
      phase: 0,
      action: null,
    },
    // Admin - Dashboards
    {
      text: "mytrace has many different dashboards available:",
      sort: 1,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Vendor Orders: View all in-progress Vendor Orders. By default all states/providences will be shown, but this can be filtered by state.`,
      sort: 2,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Manufacturing: View a list of all in-progress Work Orders for a selected location.`,
      sort: 2,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Fulfillment: Filters a list of in-progress Sales Orders based on the user's state in a Submitted => Fulfilled status. Orders in a "Awaiting Approval" status will not be shown until they are approved & updated into the "Submitted" status.`,
      sort: 3,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Sales Rep: Filters all the related leads/accounts, sales orders, store events & trainings for the logged in user. Highlights MTD sales & any open balances they are responsible for collecting.`,
      sort: 4,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Lead Prospector: Similar to the sales rep dashboard, but more of a focus on cold/hot leads.`,
      sort: 5,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Sales Manager: Allows a sales manager to see a sales rep dashboard & their pipeline in real-time. Can update monthly goals for the reps at once.`,
      sort: 6,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Brand Ambassador: Will filter Store Events assigned to a brand ambassador & show driving directions.`,
      sort: 7,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Driver: Will filter a list of all assigned delivery routes from Sales => Delivery Routes for the logged in user.`,
      sort: 8,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    {
      text: `Projects: Shows a user any active projects within Admin => Projects.`,
      sort: 9,
      related: "dashboards",
      phase: 0,
      action: null,
    },
    // Sourcing - Retail Brand
    {
      text: "A new brand can be added from Sourcing => Retail Brands.",
      sort: 1,
      related: "addRetailBrand",
      phase: 1,
      action: {
        link: "brands-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click the "+ Retail Brand" button to add a new brand. If this button is disabled, additional brand slots can be purchased.`,
      sort: 2,
      related: "addRetailBrand",
      phase: 1,
      action: null,
    },
    {
      text: `Complete the fields in yellow to submit. The "Available Payment Terms" will determine what payment terms are available when your account's place their own orders on mytrace's retail partner platform.`,
      sort: 3,
      related: "addRetailBrand",
      phase: 1,
      action: null,
    },
    // Sourcing - Product Category
    {
      text: "A new brand can be added from Sourcing => Retail Brand Products.",
      sort: 1,
      related: "addProductCategory",
      phase: 1,
      action: {
        link: "products-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click the "+ Category" button to begin adding a new category. Select the brand this category should be associated with & complete the fields in yellow. All of the required fields to submit are shown in the "Submission Checklist".`,
      sort: 2,
      related: "addProductCategory",
      phase: 1,
      action: null,
    },
    {
      text: `"Components" (individual parts needed for manufacturing: boxes/inserts/stickers...) can be set for this category which can then be placed on a Vendor Order for use with mytrace's Work Order manufacturing feature.`,
      sort: 3,
      related: "addProductCategory",
      phase: 1,
      action: null,
    },
    {
      text: `If this product category uses Raw Materials, this value will be used when calculating the needed material for a Work Order (Each unit is 1 gram of raw material, 1,000 units = 1,000 grams).`,
      sort: 3,
      related: "addProductCategory",
      phase: 1,
      action: null,
    },
    {
      text: `If this product category is available for sale through a Sales Order, select the "For Sale" option. The MSRP/list price & additional parameters can be set within this option.`,
      sort: 4,
      related: "addProductCategory",
      phase: 1,
      action: null,
    },
    {
      text: `The "Visible in Store Events" option will make this category be shown when completing a summary for a Store Event.`,
      sort: 5,
      related: "addProductCategory",
      phase: 1,
      action: null,
    },
    // Sourcing - Products
    {
      text: "New products can be added to an existing product category from Sourcing => Retail Brand Products.",
      sort: 1,
      related: "addProducts",
      phase: 1,
      action: {
        link: "products-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click the "+ Products" button to begin adding a new products. Select the related brand/category these products should be associated with.`,
      sort: 2,
      related: "addProducts",
      phase: 1,
      action: null,
    },
    {
      text: `A list of the existing products in this category will be shown. The details of each of these products can be updated in this view using the orange "Edit" button to the right`,
      sort: 3,
      related: "addProducts",
      phase: 1,
      action: {
        link: "",
        label: "edit",
        icon: "mdi-pencil",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: true,
      },
    },
    {
      text: `New products can be added using the "+" button in the top right of the New Products table. All new products added need to be locked to submit. The header of this table will change from blue => black when no further changes are needed.`,
      sort: 4,
      related: "addProducts",
      phase: 1,
      action: null,
    },
    // Sourcing - Location
    {
      text: "A new warehouse location can be added from Sourcing => Locations.",
      sort: 1,
      related: "addLocation",
      phase: 1,
      action: {
        link: "locations-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click the "+ Location" button to begin add a new location. Complete the fields in yellow to submit.`,
      sort: 2,
      related: "addLocation",
      phase: 1,
      action: {
        link: "locations-new",
        label: "location",
        icon: "mdi-plus",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Locations that are marked as a "Fulfillment" are used for manufactruing & will have access to raw materials/work orders.`,
      sort: 3,
      related: "addLocation",
      phase: 1,
      action: null,
    },
    // Sourcing - Vendor Order, New
    {
      text: "A new vendor order can be created from Sourcing => Vendor Orders.",
      sort: 1,
      related: "addVendorOrder",
      phase: 1,
      action: {
        link: "vendorOrders-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click the "+ Vendor Order" button to begin creating a new purchase order. Select the related brand/categories & delivery location.`,
      sort: 2,
      related: "addVendorOrder",
      phase: 1,
      action: {
        link: "vendorOrder-brand-new",
        label: "order",
        icon: "mdi-plus",
        color: "#2196F3",
        margin: "",
        iconPosition: "left",
        disabled: false,
      },
    },
    {
      text: `Set the QTY for each product in the "QTY" column. If the same QTY of each product is being purchased, the gray "QTY To Spread" field can be used to apply the same QTY at once.`,
      sort: 3,
      related: "addVendorOrder",
      phase: 1,
      action: {
        link: "",
        label: "apply",
        icon: "mdi-arrow-down",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: true,
      },
    },
    {
      text: `The "Edit" button can be used to edit the components being purchased for that SKU. If an individual component is removed, the overall cost will automatically be updated.`,
      sort: 4,
      related: "addVendorOrder",
      phase: 1,
      action: null,
    },
    {
      text: `The "Lock All" button can be used to confirm all of the selected products at once, instead of having to individually lock them one-by-one.`,
      sort: 4,
      related: "addVendorOrder",
      phase: 1,
      action: null,
    },
    {
      text: `Once the final details of this order are set (vendor/order date), this order can be submitted. The components of this order will now be shown at "Incoming" inventory to the delivery location.`,
      sort: 5,
      related: "addVendorOrder",
      phase: 1,
      action: null,
    },
    // Sourcing - Vendor Orders, Update
    {
      text: "Search for your Vendor Order from Sourcing => Vendor Orders.",
      sort: 1,
      related: "updateVendorOrder",
      phase: 1,
      action: {
        link: "vendorOrders-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Set the filters & click "Search". Click the "View" button to see the details of the Vendor Order.`,
      sort: 2,
      related: "updateVendorOrder",
      phase: 1,
      action: null,
    },
    {
      text: `The component invoices (hardware/packaging invoices) shows the delivery & payment status of each invoice. Each section of the invoice is broken into a different panel (Components/Shipments/Payments).`,
      sort: 3,
      related: "updateVendorOrder",
      phase: 1,
      action: null,
    },
    {
      text: `The "Edit" button at the bottom of the 'Components' view will show a dialog where the QTY can be marked as received.`,
      sort: 4,
      related: "updateVendorOrder",
      phase: 1,
      action: null,
    },
    {
      text: `When the component line items are updated, this inventory is immediately available at the delivery location. If the total QTY is set within the "Received" column, only the 'Shipment Complete' button is available as there are no further updates.`,
      sort: 5,
      related: "updateVendorOrder",
      phase: 1,
      action: null,
    },
    {
      text: `Tracking numbers can be added to this order from the "Shipments" panel. Similar to the components, an "Edit" button is available at the bottom of the panel to update the status of this tracking number.`,
      sort: 6,
      related: "updateVendorOrder",
      phase: 1,
      action: null,
    },
    {
      text: `Payments can be added to this Vendor Order from the "Payments" panel. As payments are added to the order, the balance will update in real-time. Multiple payments can be added at once with the "+" at the top right of the payments table.`,
      sort: 7,
      related: "updateVendorOrder",
      phase: 1,
      action: null,
    },
    // Manufacturing - Add brand/product category
    {
      text: "Search for your location from Sourcing => Locations.",
      sort: 1,
      related: "addBrandToLocation",
      phase: 2,
      action: {
        link: "locations-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `After searching & selecting your location, click the "Edit" button in the top right corner`,
      sort: 2,
      related: "addBrandToLocation",
      phase: 2,
      action: {
        link: "",
        label: "edit",
        icon: "mdi-pencil",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: true,
      },
    },
    {
      text: `Select the 1st update option "Brand/Product Category - Add to location"`,
      sort: 3,
      related: "addBrandToLocation",
      phase: 2,
      action: null,
    },
    {
      text: `After selecting the brand/category, a list of active products will be shown. Once submitted, these new SKUs will be available in the location's inventory.`,
      sort: 4,
      related: "addBrandToLocation",
      phase: 2,
      action: null,
    },
    // Manufacturing - Add brand/product category
    {
      text: "Search for your location from Sourcing => Locations.",
      sort: 1,
      related: "reconcileInventory",
      phase: 2,
      action: {
        link: "locations-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `After searching & selecting your location, click the black "Reconcile" button within the main page`,
      sort: 2,
      related: "reconcileInventory",
      phase: 2,
      action: {
        link: "",
        label: "reconcile",
        icon: "mdi-help-network",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "left",
        disabled: true,
      },
    },
    {
      text: `Select a brand & choose the reconcile type (assembled/components). Assembled/Reserved/Failed are related to assembled units while Components/Missing/Defective are related to individual hardware/packaging parts.`,
      sort: 3,
      related: "reconcileInventory",
      phase: 2,
      action: null,
    },
    {
      text: `Assembled: "Assembled" inventory is ready-for-sale & is shown on sales orders. The amount to be set is a sum of available + reserved. Moving from right => left, the total amount = available+reserved. Adjusting the "Available" column will do the math of what number should be set in the "Amount" column.`,
      sort: 4,
      related: "reconcileInventory",
      phase: 2,
      action: null,
    },
    {
      text: `Components: "component" inventory is the individual parts (hardware/packaging) for use in work orders. These are separated between SKU specific (can only be used on the related SKU) or generic where it could be used on multiple (a clear sticker to make the product tamper-proof).`,
      sort: 5,
      related: "reconcileInventory",
      phase: 2,
      action: null,
    },
    {
      text: `After submitting the reconciled amount, a "reconcile receipt" will be created taking a snapshot of what the inventory QTY was at before submission. Updated QTYs will be highlighted in orange.`,
      sort: 6,
      related: "reconcileInventory",
      phase: 2,
      action: null,
    },
    // Sales - Create new account
    {
      text: "Navigate to Sales => Accounts",
      sort: 1,
      related: "addSalesAccount",
      phase: 3,
      action: {
        link: "accounts-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click the blue "+ Account" button in the top right of the table.`,
      sort: 2,
      related: "addSalesAccount",
      phase: 3,
      action: {
        link: "accounts-new",
        label: "account",
        icon: "mdi-plus",
        margin: "mr-1",
        color: "#2196F3",
        iconPosition: "left",
        disabled: false,
      },
    },
    {
      text: `Search by the account's FULL license number to prevent duplicates. If no duplicate is found, complete all the fields with a yellow background to submit.`,
      sort: 3,
      related: "addSalesAccount",
      phase: 3,
      link: "",
      action: null,
    },
    {
      text: `An account profile is split between the brand (payment terms/staff managing/status) & storefront information (store name/shipping address).`,
      sort: 4,
      related: "addSalesAccount",
      phase: 3,
      link: "",
      action: null,
    },
    {
      text: `A history of all changes to this account can be seen in the "Ledger" at the bottom of the Account profile. Each time the account profile is viewed by a user, this will be shown within the "Last Viewed" tab.`,
      sort: 5,
      related: "addSalesAccount",
      phase: 3,
      link: "",
      action: null,
    },
    // Sales - Update account
    {
      text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
      sort: 1,
      related: "updateSalesAccount",
      phase: 3,
      action: {
        link: "accounts-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click the orange "Edit" button at the top of the account profile.`,
      sort: 2,
      related: "updateSalesAccount",
      phase: 3,
      action: {
        link: "",
        label: "edit",
        icon: "mdi-pencil",
        color: "orange",
        margin: "",
        iconPosition: "right",
        disabled: true,
      },
    },
    {
      text: `Select your update option to return a list of the related fields for this option. Any fields that have been updated will have an orange background.`,
      sort: 3,
      related: "updateSalesAccount",
      phase: 3,
      action: null,
    },
    {
      text: `Click the "Update" button to submit changes. These will be notated in the Ledger at the bottom of the account profile.`,
      sort: 4,
      related: "updateSalesAccount",
      phase: 3,
      action: {
        link: "",
        label: "update",
        icon: "mdi-check",
        margin: "mr-1",
        color: "orange",
        iconPosition: "right",
        disabled: true,
      },
    },
    // Sales - Contacts
    {
      text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
      sort: 1,
      related: "addSalesContact",
      phase: 3,
      action: {
        link: "accounts-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click blue "+ Contact" within the "Contacts" panel in the account profile.`,
      sort: 2,
      related: "addSalesContact",
      phase: 3,
      action: {
        link: "",
        label: "contact",
        icon: "mdi-plus",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "left",
        disabled: true,
      },
    },
    {
      text: `Input the contact's first name & title (the address will default to the store's shipping address). Contacts require at least an email OR a phone number to submit, but both can be added.`,
      sort: 3,
      related: "addSalesContact",
      phase: 3,
      action: null,
    },
    {
      text: `Contacts are required to submit Sales Orders!`,
      sort: 4,
      related: "addSalesContact",
      phase: 3,
      action: null,
    },
    // Sales - Tasks
    {
      text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
      sort: 1,
      related: "addSalesTasks",
      phase: 3,
      action: {
        link: "accounts-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Navigate to the "Tasks" panel in the account profile.`,
      sort: 2,
      related: "addSalesTasks",
      phase: 3,
      action: null,
    },
    {
      text: `Complete the fields with a yellow background to submit. The task will be assigned to your user by default but can be re-assigned to others. When a new task is submitted, the "Last Task" date on the account will update.`,
      sort: 3,
      related: "addSalesTasks",
      phase: 3,
      action: null,
    },
    {
      text: `Pending tasks will be shown in the "Pending" section of an account profile & on the assigned user's dashboard. The history of all tasks on this account are shown in the "Completed" tab`,
      sort: 4,
      related: "addSalesContact",
      phase: 3,
      action: null,
    },
    // Sales - Create sales order
    {
      text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
      sort: 1,
      related: "newSalesOrder",
      phase: 3,
      action: {
        link: "accounts-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click "+ Order" within the "Sales Order" panel in the account profile.`,
      sort: 2,
      related: "newSalesOrder",
      phase: 3,
      action: {
        link: "",
        label: "order",
        icon: "mdi-plus",
        margin: "mr-1",
        color: "#2196F3",
        iconPosition: "left",
        disabled: true,
      },
    },
    {
      text: `Set the order type (Sales/Sample), payment term & begin adding SKUs. Individual SKUs can be added with the blue "Edit" button or "Add Multiple" can be used for multiple SKUs of the same category at once. "Filter and Sort" can be used to filter the menu based on metadata like category/previously purchased...`,
      sort: 3,
      related: "newSalesOrder",
      phase: 3,
      action: null,
    },
    {
      text: `Promo units can be added to an order using the "Promo Mode" toggle. This is only available on SALES orders & the promo % will be calculated within the "Cart" details view before submitting`,
      sort: 4,
      related: "newSalesOrder",
      phase: 3,
      action: null,
    },
    {
      text: `A "Draft" order can be saved after adding 1+ SKU to the cart. After clicking the Cart => "Checkout" button, an option to "Save Draft" is available.`,
      sort: 5,
      related: "newSalesOrder",
      phase: 3,
      action: null,
    },
    {
      text: `Confirm the contact from the account who approved this order. If the contact has not been added to the account, the "+ New Contact" at the bottom of the "Buyer" field will add the contact to the store without having to go back to the Account profile.`,
      sort: 6,
      related: "newSalesOrder",
      phase: 3,
      action: null,
    },
    {
      text: `Most orders will start in a "Submitted" status, but if the account has an existing balance / promo units have been added / etc, the order will start in an "Awaiting Approval" status.`,
      sort: 7,
      related: "newSalesOrder",
      phase: 3,
      action: null,
    },
    // Sales - Sales Orders, Process
    {
      text: `Sales Orders are split into draft/sales (in-progress) & delivered orders. `,
      sort: 1,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    {
      text: `"Draft" orders have not been submitted yet & the inventory has not been put on reserve. Because mytrace only allows orders to be submitted when the inventory is available, a draft order also functions as a backorder until the QTY is available.`,
      sort: 2,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    {
      text: `The "Sales Order" view is where all of the in-progress orders are. The statuses follow the direction of the tabs from left => right. Orders start in a "Awaiting Approval"/"Submitted" status & move to "Fulfilled".`,
      sort: 3,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    {
      text: `Awaiting Approval/Submitted: Initial status of a sales order. Orders will be in "Awaiting Approval" if the account has an existing balance when the order was submitted, promo units were added to the order/etc.`,
      sort: 4,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    {
      text: `Accepted: When the order has been reviewed & is ready to be fulfilled, the order's status will updated from Submitted => Accepted.`,
      sort: 5,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    {
      text: `Delivery Confirmation: Once the line item's of the order have been fulfilled, the status is updated to "Delivery Confirmation". The step is meant for confirming a delivery date with the account & any final steps needed for this order to be ready to deliver.`,
      sort: 6,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    {
      text: `--If line items on the order are updated while it is in the "Delivery Confirmation" status, the order will be moved back to "Accepted"!`,
      sort: 7,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    {
      text: `Fulfilled: The order is ready to be delivered with a ship date confirmed & all line items fulfilled. Once the order has been delivered, this order will be in the "Delivered Orders" view until the balance is cleared. If the balance is $0 when the order is marked as shipped, it will automatically go into "Complete".`,
      sort: 8,
      related: "salesOrderProcess",
      phase: 3,
      action: null,
    },
    // Support - Store Event, New
    {
      text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
      sort: 1,
      related: "newStoreEvent",
      phase: 3,
      action: {
        link: "accounts-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click "+ Store Event" within the "Store Events" panel in the account profile.`,
      sort: 2,
      related: "newStoreEvent",
      phase: 3,
      action: {
        link: "",
        label: "store event",
        icon: "mdi-plus",
        margin: "mr-1",
        color: "#2196F3",
        iconPosition: "left",
        disabled: true,
      },
    },
    {
      text: `Set the event type (in-person/ghost/brand presence) & fields like date/time, assigned user & promotion`,
      sort: 3,
      related: "newStoreEvent",
      phase: 3,
      action: null,
    },
    {
      text: `In-Person: A "in person" event requires a date/time/assigned user & promotion.`,
      sort: 4,
      related: "newStoreEvent",
      phase: 3,
      action: null,
    },
    {
      text: `Brand presence: A "brand presence" event is similar to a in-person event, but does not require a promotion to be set.`,
      sort: 5,
      related: "newStoreEvent",
      phase: 3,
      action: null,
    },
    {
      text: `ghost: A "ghost" event has a date/promotion, but is ran by the account & does not require an assigned user/time.`,
      sort: 6,
      related: "newStoreEvent",
      phase: 3,
      action: null,
    },
    {
      text: `Promotions: If a promotion is marked as needing "manager approval", the store event will start in a "Approval" status before moving forward.`,
      sort: 7,
      related: "newStoreEvent",
      phase: 3,
      action: null,
    },
    // Support - Store Event, New
    {
      text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
      sort: 1,
      related: "newTraining",
      phase: 3,
      action: {
        link: "accounts-overview",
        label: "view",
        icon: "mdi-eye",
        color: "#2196F3",
        margin: "mr-1",
        iconPosition: "right",
        disabled: false,
      },
    },
    {
      text: `Click "+ Training" within the "Trainings" panel in the account profile.`,
      sort: 2,
      related: "newTraining",
      phase: 3,
      action: {
        link: "",
        label: "training",
        icon: "mdi-plus",
        margin: "mr-1",
        color: "#2196F3",
        iconPosition: "left",
        disabled: true,
      },
    },
    {
      text: `Set if the training will be live or offline. If the training is live, set the date/time/training format (in-person/online)`,
      sort: 3,
      related: "newTraining",
      phase: 3,
      action: null,
    },
    {
      text: `If the training is offline, the URL for the related training materials & a brief description of what was sent will be requested.`,
      sort: 4,
      related: "newTraining",
      phase: 3,
      action: null,
    },
    {
      text: `Along with details about the training, the preparation section is used to estimate the amount of staff members being trained & any additional elements like if food / swag will be provided for the staff.`,
      sort: 5,
      related: "newTraining",
      phase: 3,
      action: null,
    },
    {
      text: `If the fields for food/swag/samples are set to "Yes" / "Pending", this will create a task for the assigned trainer.`,
      sort: 6,
      related: "newTraining",
      phase: 3,
      action: null,
    },
  ],
  notification: {
    dialog: false,
    record: null,
    loading: false,
    users: [],
  },
  actionList: {
    dialog: false,
    record: null,
    loading: false,
    contacts: [],
  },
  alert: {
    dialog: false,
    record: null,
    collection: "",
    loading: false,
  },
  email: {
    dialog: false,
    record: null,
    loading: false,
    users: [],
  },
  rightDrawer: {
    active: false,
  },
};

const actions = {
  async _notification({ commit }, payload) {
    let users = [];

    commit("setNotificationUsers", users);
    commit("setNotificationDialog", payload.dialog);
    if (payload.dialog) {
      commit("setNotificationUsersLoading", true);
      const usersRef = collection(firestore, "users");
      const q = query(
        usersRef,
        where("active", "==", true),
        where("accountType", ">=", 2)
      );
      const response = await getDocs(q);

      if (!response.empty) {
        users = response.docs
          .map((user) => {
            return {
              text: `${user.data().name.first} ${user.data().name.last}`,
              name: user.data().name,
              department: user.data().department ? user.data().department : "-",
              email: user.data().email,
              value: user.id,
            };
          })
          .sort((a, b) => (a.name.first > b.name.first ? 1 : -1));
        commit("setNotificationUsers", users);
        commit("setNotificationUsersLoading", false);
      }

      commit("setNotificationRecord", {
        notes: "",
        assignedTo: [],
        related: payload.related,
      });
    }
  },
  async _actionList({ commit }, payload) {
    commit("setActionListRecord", null);
    commit("setActionListDialog", payload.dialog);

    if (payload.dialog) {
      commit("setActionListRecord", payload.related);
    }
  },
  async _recordAlert({ commit }, payload) {
    commit("setAlertRecord", null);
    commit("setAlertDialog", payload.dialog);

    if (payload.dialog) {
      commit("setAlertRecord", payload.related);
    }
  },
  async _email({ commit }, payload) {
    let users = [],
      cc = [],
      contacts = [],
      templates = [];

    commit("setEmailUsers", users);
    commit("setEmailDialog", payload.dialog);
    if (payload.dialog) {
      // Contacts
      const relatedID = payload.related.id;
      const contactsRef = collection(firestore, "contacts");
      const contactsQ = query(
        contactsRef,
        where("active", "==", true),
        where("related.id", "==", relatedID),
        where("title", "!=", "customer")
      );
      const contactsResponse = await getDocs(contactsQ);
      if (!contactsResponse.empty) {
        contacts = contactsResponse.docs
          .map((contact) => {
            return {
              id: contact.id,
              text: `${contact.data().name.first} ${contact.data().name.last}`,
              title: contact.data().title,
              name: contact.data().name,
              email: contact.data().email,
              value: contact.data().email,
            };
          })
          .sort((a, b) => (a.name.first > b.name.first ? 1 : -1));
      }

      // Users
      commit("setEmailUsersLoading", true);
      const usersRef = collection(firestore, "users");
      const q = query(
        usersRef,
        where("active", "==", true),
        where(documentId(), "!=", auth.currentUser.uid)
      );
      const response = await getDocs(q);

      if (!response.empty) {
        users = response.docs
          .map((user) => {
            return {
              text: `${user.data().name.first} ${user.data().name.last}`,
              name: user.data().name,
              department: user.data().department ? user.data().department : "-",
              id: user.id,
              email: user.data().email,
              value: user.data().email,
              reportsTo: user.data().reportsTo,
            };
          })
          .sort((a, b) => (a.name.first > b.name.first ? 1 : -1));
        commit("setEmailUsers", users);
        commit("setEmailUsersLoading", false);

        if (payload.related.staff) {
          payload.related.staff.forEach((userID) => {
            const selectedUser = users.find((option) => option.id === userID);
            if (selectedUser) {
              cc.push(selectedUser.email);
            }
          });
        }
        console.log(payload);

        const templatesRef = collection(firestore, "emailTemplates");
        const templatesQ = query(
          templatesRef,
          where("active", "==", true),
          where("phase", "==", payload.related.phase)
        );
        const templatesResponse = await getDocs(templatesQ);
        if (!templatesResponse.empty) {
          templates = templatesResponse.docs
            .map((template) => {
              return {
                created: moment(
                  template.data().created.seconds * 1000
                ).toDate(),
                updated: moment(
                  template.data().updated.seconds * 1000
                ).toDate(),
                subject: template.data().subject,
                body: template.data().body,
                value: template.id,
              };
            })
            .sort((a, b) => (a.subject > b.subject ? 1 : -1));
        }
      }

      commit("setEmailRecord", {
        subject: "",
        body: "",
        files: [],
        to: [],
        cc,
        bcc: [],
        templates,
        contacts,
        related: payload.related,
        staff: payload.related.staff || [],
        phase: payload.related.phase,
        template: null,
        attachments: [],
      });
    }
  },
  _rightDrawer({ commit }, payload) {
    commit("setRightDrawer", payload);
  },
};

const mutations = {
  // Notification
  setNotificationDialog(state, payload) {
    state.notification.dialog = payload;
    this.rightDrawer;
  },
  setNotificationRecord(state, payload) {
    state.notification.record = payload;
  },
  setNotificationUsers(state, payload) {
    state.notification.users = payload;
  },
  setNotificationUsersLoading(state, payload) {
    state.notification.loading = payload;
  },
  // Action List
  setActionListDialog(state, payload) {
    state.actionList.dialog = payload;
  },
  setActionListRecord(state, payload) {
    state.actionList.record = payload;
  },
  setNotificationContacts(state, payload) {
    state.notification.users = payload;
  },
  setActionListLoading(state, payload) {
    state.actionList.loading = payload;
  },
  // Alerts
  setAlertDialog(state, payload) {
    state.alert.dialog = payload;
  },
  setAlertRecord(state, payload) {
    state.alert.record = payload;
  },
  // Email
  setEmailDialog(state, payload) {
    state.email.dialog = payload;
  },
  setEmailRecord(state, payload) {
    state.email.record = payload;
  },
  setEmailUsers(state, payload) {
    state.email.users = payload;
  },
  setEmailContacts(state, payload) {
    state.email.contacts = payload;
  },
  setEmailUsersLoading(state, payload) {
    state.email.loading = payload;
  },
  setRightDrawer(state, payload) {
    state.rightDrawer = payload;
  },
};

const getters = {
  getAppName(state) {
    return state.appName;
  },
  getPhases(state) {
    return state.phases;
  },
  getPhaseViews(state) {
    return state.phaseViews;
  },
  getReports(state) {
    return state.reports;
  },
  getLocationTypes(state) {
    return state.locationTypes;
  },
  getContactTitles(state) {
    return state.contactTitles;
  },
  getContactPreferences(state) {
    return state.contactPreferences;
  },
  getIntegrations(state) {
    return state.integrations;
  },
  getCountryCodes(state) {
    return state.countryCodes;
  },
  getFileUploadTypes(state) {
    return state.fileUploadTypes;
  },
  // Notifications
  getNotificationDialog(state) {
    return state.notification.dialog;
  },
  getNotificationRecord(state) {
    return state.notification.record;
  },
  getNotificationUsers(state) {
    return state.notification.users;
  },
  getNotificationUsersLoading(state) {
    return state.notification.loading;
  },
  // Action List
  getActionListDialog(state) {
    return state.actionList.dialog;
  },
  getActionListRecord(state) {
    return state.actionList.record;
  },
  // Alert
  getAlertDialog(state) {
    return state.alert.dialog;
  },
  getAlertRecord(state) {
    return state.alert.record;
  },
  // Emails
  getEmailDialog(state) {
    return state.email.dialog;
  },
  getEmailRecord(state) {
    return state.email.record;
  },
  getEmailUsers(state) {
    return state.email.users;
  },
  getEmailUsersLoading(state) {
    return state.email.loading;
  },
  getRightDrawer(state) {
    return state.rightDrawer;
  },
  // Helpdesk
  getHelpdeskGuides(state) {
    return state.helpdeskGuides;
  },
  getHelpdeskInstructions(state) {
    return state.helpdeskInstructions;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
